@charset "UTF-8";
/* cyrillic-ext */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/MerriweatherItalic-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/MerriweatherItalic-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/MerriweatherItalic-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/MerriweatherItalic-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSansItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSansItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSansItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/Merriweather-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/Merriweather-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/Merriweather-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/Merriweather-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/Merriweather-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/Merriweather-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/Merriweather-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/Merriweather-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/Merriweather-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/Merriweather-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSans-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSans-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSans-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSans-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSans-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSans-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSans-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSans-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSans-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/**
 * Enhanced
 * Bootstrap the rest of the styles on cut the mustard
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Breakpoints
 * Shared media query values
 */
/**
 * Shared colors
 * Applicable to all brands and not to be overwritten
 *
 */
/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 *  Button settings
 *  Default
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Map deep get
 * @author Hugo Giraudel
 * @private
 *
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Desired value
 *
 */
/**
 * Map key get
 * Check if a key exists in a map, return it
 * @private
 *
 * @param {Map} $map - Map
 * @param {String} $value - Key name
 * @return {*} - Key name, if found
 *
 */
/**
 * Default link style
 *
 */
/**
 * Link Text
 */
/**
 * Breakpoints
 * Media query helper
 * @group 30-mixins
 */
/**
 * Basic
 * Some default CSS styles
 */
body {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.8;
}

a {
  color: #025e8d;
}

button {
  cursor: pointer;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/**
 * Typography
 * Set base font size
 */
html {
  font-size: 100%;
}

body {
  font-size: 1.125rem;
}

/*
 * Headings
 */
h1 {
  font-size: 2.25rem;
  font-size: clamp(1.75rem, 4vw, 2.25rem);
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: 1.75rem;
  font-size: clamp(1.5rem, 4vw, 1.75rem);
  font-weight: 700;
  line-height: 1.2;
}

h3 {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

/**
 * Layout
 * Universal layout styles
 */
html {
  height: 100%;
  overflow-y: scroll;
  font-size: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: 100%;
  font-size: 1.125rem;
  color: #222222;
  background: #ffffff;
}

abbr[title] {
  text-decoration: none;
}

dd {
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 2rem;
}

p:last-of-type {
  margin-bottom: 0;
}

a {
  color: #025e8d;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
a.visited, a:visited {
  color: #01324b;
}
a.hover, a:hover {
  color: #01324b;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
a.active, a:active {
  color: #025e8d;
}
a.focus, a:focus {
  outline: 4px solid #0088cc;
}

table {
  font-size: 1rem;
  line-height: 1.8;
  border-collapse: collapse;
  border: none;
  width: 100%;
  margin-bottom: 32px;
}

th,
td {
  padding: 8px;
  vertical-align: top;
}

th {
  text-align: left;
  border-bottom: 3px solid #01324b;
}

td {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 2px solid #dadada;
}

/**
 * Forms
 * Default form styles
 */
button:not([disabled]):focus,
input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus,
[contenteditable]:focus {
  outline: 4px solid #0088cc;
}

input[type=file]:focus-within {
  outline: 4px solid #0088cc;
}

p {
  margin-top: 0;
  margin-bottom: 32px;
}

p:last-of-type {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.33333em;
}

.c-breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-size: min(max(0.875rem, 2vw), 1rem);
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
}
.c-breadcrumbs > li {
  display: inline;
}

.c-breadcrumbs__link {
  color: #025e8d;
}
.c-breadcrumbs__link.visited, .c-breadcrumbs__link:visited, .c-breadcrumbs__link.hover, .c-breadcrumbs__link:hover {
  color: #025e8d;
}

svg.c-breadcrumbs__chevron {
  margin: 0 0.25rem;
  fill: #333;
  width: 10px;
  height: 10px;
}

.c-breadcrumbs--contrast {
  color: #fff;
}
.c-breadcrumbs--contrast .c-breadcrumbs__link {
  color: #fff;
}
.c-breadcrumbs--contrast .c-breadcrumbs__link.visited, .c-breadcrumbs--contrast .c-breadcrumbs__link:visited, .c-breadcrumbs--contrast .c-breadcrumbs__link.hover, .c-breadcrumbs--contrast .c-breadcrumbs__link:hover {
  color: #fff;
}
.c-breadcrumbs--contrast svg.c-breadcrumbs__chevron {
  fill: #fff;
}

@media only screen and (max-width: 479px) {
  .c-breadcrumbs .c-breadcrumbs__item {
    display: none;
  }
  .c-breadcrumbs .c-breadcrumbs__item:nth-last-child(1),
  .c-breadcrumbs .c-breadcrumbs__item:nth-last-child(2) {
    display: inline;
  }
}
.eds-c-section-heading {
  border-bottom: 1px solid #c5e0f4;
  padding-bottom: 8px;
  width: 100%;
}

.eds-c-section-heading--darker-underline {
  border-color: #222222;
}

.eds-c-section-heading--aside {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

/**
* @springernature/eds/forms
* Default skin settings
*
*/
.c-forms__label,
.c-forms__hint,
.c-forms__error,
.c-forms__legend {
  display: block;
}

.c-forms__hint,
.c-forms__error {
  font-weight: normal;
}

.c-forms__input-container {
  position: relative;
}

.c-forms__fieldset,
.c-forms__error-summary,
.c-forms__field,
.c-forms__field * {
  line-height: 1.4;
}

.c-forms__field,
.c-forms__error-summary {
  font-size: 1rem;
}

.c-forms__field small {
  font-size: inherit;
}

.c-forms__fieldset,
.c-forms__legend {
  padding: 0;
  border: 0;
}

.c-forms__error-summary,
.c-forms__input-container,
.c-forms__field {
  max-width: 70ch;
}

.c-forms__inline-fields {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: var(--form-inline-gap, 0.5rem);
}

.c-forms__inline-fields--nowrap {
  flex-wrap: nowrap;
}

.c-forms__inline-fields .c-forms__field--formText {
  /* ↓ Make it max out the available space with a super high value */
  flex-grow: 666;
}

.c-forms__inline-fields .c-forms__input {
  height: 100%;
}

.c-forms__pictographic-radios {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
}

@media only screen and (min-width: 480px) {
  .c-forms__pictographic-radios {
    flex-direction: row;
  }
  .c-forms__pictographic-radios .c-forms__label + .c-forms__label {
    margin-top: 0;
  }
}
* + .c-forms__error-summary,
* + .c-forms__fieldset,
* + .c-forms__field {
  margin-top: 1.5rem;
}

.c-forms__inline-fields * {
  margin: 0;
}

.c-forms__field .u-visually-hidden + * {
  margin-top: 0;
}

.c-forms__legend * {
  margin: 0;
}

.c-forms__input {
  box-sizing: border-box;
  border: 1px solid;
  color: #000;
  width: 100%;
  padding: 0.5rem 0.5rem;
  background-color: #fff;
  font-weight: 400;
}

* + .c-forms__input,
* + .c-forms__input-container {
  margin-top: 0.25rem;
}

.c-forms__input:focus {
  outline: 3px solid #08c;
}

.c-forms__input[invalid] {
  border-color: #c40606;
  border-width: 2px;
}

.c-forms__input[disabled] {
  cursor: not-allowed;
  border-color: #dadada;
}

.c-forms__label {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  padding: 0;
}

.c-forms__label + .c-forms__label {
  margin-top: 0.25rem;
}

.c-forms__hint {
  font-weight: 400;
  margin-top: 0.25rem;
  color: #666;
}

.c-forms__hint--inline {
  display: inline-block;
}

.c-forms__error {
  color: #c40606;
  display: flex;
  align-items: flex-start;
}

label + .c-forms__error,
.c-forms__error + label {
  margin-top: 0.25rem;
}

.c-forms__error .c-forms__icon {
  color: #c40606;
}

.c-forms__icon svg {
  height: 1rem;
  width: 1rem;
  vertical-align: calc(0.5rem / 8 * -1);
  overflow: visible;
  pointer-events: none;
}

.c-forms__input--select {
  cursor: pointer;
  appearance: none;
  padding-right: 1.5rem;
}

.c-forms__input-container--select .c-forms__icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.c-forms__input--radio,
.c-forms__input--checkbox,
.c-form__label--visually-hidden {
  /* hide visually, not to assistive tech */
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.c-forms__label--inline {
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  padding: 0;
}

.c-forms__label--inline::before {
  content: "";
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  border: 2px solid #999;
  margin-right: 0.5rem;
}

@media only screen and (min-width: 1024px) {
  .c-forms__field--small + .c-forms__field--small {
    margin-top: 1rem;
  }
}
.c-forms__field--small .c-forms__label {
  font-size: 0.875rem;
}
.c-forms__field--small .c-forms__label--inline::before {
  width: 1rem;
  height: 1rem;
}

.c-forms__label--pictographic-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-weight: 400;
  cursor: pointer;
}

@media only screen and (min-width: 480px) {
  .c-forms__label--pictographic-radio {
    flex-direction: column;
    justify-content: space-between;
  }
}
.c-forms__label--pictographic-radio svg {
  box-sizing: content-box;
  flex: 0 0 auto;
  width: var(--forms--pictographic-radio-icon-size, 4rem);
  height: var(--forms--pictographic-radio-icon-size, 4rem);
  fill: #fff;
  stroke: #01324b;
  background-color: #fff;
}

:checked + .c-forms__label--pictographic-radio svg {
  fill: #01324b;
  background-color: #01324b;
  stroke: #fff;
}

.c-forms__label--boxed-icon svg {
  padding: 0.5rem;
  border: 2px solid #999;
}

:checked + .c-forms__label--boxed-icon svg {
  border-color: #fff;
}

:focus + .c-forms__label--pictographic-radio svg,
.c-forms__label--pictographic-radio:hover svg {
  box-shadow: 0 0 0 3px #08c;
}

:checked + .c-forms__label--inline::before {
  border-color: #01324b;
}

:focus + .c-forms__label--inline::before {
  box-shadow: 0 0 0 3px #08c;
}

.c-forms__label--radio::before {
  border-radius: 50%;
}

.c-forms__label--checkbox::before {
  border-radius: 0.125em;
}

[invalid] + .c-forms__label--checkbox::before {
  border-color: #c40606;
}

:checked + .c-forms__label--radio::before {
  background: radial-gradient(#01324b 0%, #01324b 40%, #fff 40%);
}

:checked + .c-forms__label--checkbox::before {
  background-color: #01324b;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="white" stroke-linejoin="round" fill="none" stroke-linecap="round" viewBox="0 0 20 20"><path d="M4.5,11 10,15 16,5"></path></svg>');
  border-color: #01324b;
  background-size: 100%;
  background-repeat: none;
  background-position: center;
}

.c-forms__sub-fields {
  margin-top: 0.25rem;
  margin-left: calc(1.5rem / 4);
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  position: relative;
  display: none;
}

.c-forms__sub-fields::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: calc(1.5rem / 2);
  background-color: #dadada;
  border-radius: 4px;
}

:checked ~ .c-forms__sub-fields {
  display: block;
}

.c-forms__error-summary {
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  color: #c40606;
  border: 1px solid #dadada;
  border-bottom: 4px solid #c40606;
}

.c-forms__error-summary .c-forms__icon svg {
  width: 2rem;
  height: 2rem;
}

.c-forms__error-summary-errors {
  padding: 0;
  margin-bottom: 0;
  margin-top: 0.5rem;
  list-style: none;
}

.c-forms__error-summary-errors a {
  color: inherit;
  text-decoration: underline;
}

.c-forms__error-summary-title {
  color: initial;
  font-weight: bold;
}

.c-forms__error-summary-errors li + li {
  margin-top: 0.25rem;
}

.c-header {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  line-height: 1.4;
  background-color: #fff;
  padding: 8px 0 0;
  border-bottom: 2px solid #01324b;
  font-size: 16px;
}

.c-header__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto 8px;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px 16px;
}

.c-header__nav {
  border-top: 2px solid #cedbe0;
  padding-top: 4px;
  position: relative;
}

.c-header__nav-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 0 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.c-header__nav-container > *:not(:last-child) {
  margin-right: 32px;
}

.c-header__link-container {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  gap: 8px 16px;
  justify-content: space-between;
}

.c-header__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-header__list-item {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  font-weight: bold;
  padding: 8px;
}
.c-header__list-item:not(:last-child) {
  border-bottom: 2px solid #cedbe0;
}

.c-header__item {
  color: inherit;
}

@media only screen and (min-width: 768px) {
  .c-header__item--menu {
    display: none;
    visibility: hidden;
  }
  .c-header__item--menu:first-child + * {
    margin-block-start: 0;
  }
}

.c-header__item--inline-links {
  display: none;
  visibility: hidden;
}
.c-header__item--inline-links:first-child + * {
  margin-block-start: 0;
}
@media only screen and (min-width: 768px) {
  .c-header__item--inline-links {
    display: flex;
    gap: 16px;
    visibility: visible;
  }
}

.c-header__item--divider:before {
  content: "";
  position: absolute;
  border-left: 2px solid #cedbe0;
  height: calc(100% - 16px);
  top: 8px;
  margin-left: -15px;
}

.c-header__brand a {
  display: block;
  line-height: 1;
  padding: 16px 8px;
  text-decoration: none;
}
.c-header__brand img {
  height: 24px;
  width: auto;
}

.c-header__link {
  display: inline-block;
  padding: 16px 8px;
  color: inherit;
  font-weight: bold;
  text-decoration-color: transparent;
  position: relative;
  word-break: normal;
  white-space: nowrap;
}
.c-header__link:visited {
  color: inherit;
}
.c-header__link:hover {
  color: inherit;
  text-decoration-color: inherit;
}

.c-header__link--static {
  flex: 0 0 auto;
}

.c-header__link.is-open {
  color: #025e8d;
}
.c-header__link.is-open::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.c-header__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  font-size: 24px;
}
.c-header__icon + * {
  margin-left: 8px;
}

.c-header__expander {
  background-color: #ebf1f5;
}

.c-header__search {
  padding: 24px 0;
}
@media only screen and (min-width: 768px) {
  .c-header__search {
    max-width: 70%;
  }
}

.c-header__search-container {
  position: relative;
}

.c-header__search-label {
  display: inline-block;
  color: inherit;
  font-weight: bold;
  margin-bottom: 8px;
}

.c-header__search-input {
  border: 1px solid black;
  width: 100%;
  padding: 8px 48px 8px 8px;
  background-color: white;
}

.c-header__search-button {
  background-color: transparent;
  border: none;
  color: inherit;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  right: 0;
}

.has-tethered.c-header__expander {
  margin-top: -2px;
  width: 100%;
  top: 100%;
  z-index: 10;
  left: 0;
  border-bottom: 2px solid #01324b;
}

@media only screen and (min-width: 768px) {
  .has-tethered.c-header__expander--menu {
    display: none;
    visibility: hidden;
  }
  .has-tethered.c-header__expander--menu:first-child + * {
    margin-block-start: 0;
  }
}

.has-tethered.c-header__expander--account {
  transform: translateY(8px);
  width: auto;
  left: auto;
  position: absolute;
  right: 0;
  bottom: auto;
  margin-top: 0;
}

.has-tethered .c-header__heading {
  display: none;
  visibility: hidden;
}
.has-tethered .c-header__heading:first-child + * {
  margin-block-start: 0;
}
.has-tethered .c-header__search {
  margin: auto;
}

.c-header__heading {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 16px 16px 0;
}

.c-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  border-bottom: 2px solid #999999;
  padding-bottom: 4px;
  font-size: 0.875rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.c-list-description {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  line-height: 1.8;
  margin-bottom: 0;
  width: 100%;
}

.c-list-description__item {
  margin-bottom: 16px;
}
.c-list-description__item:last-child {
  margin-bottom: 0;
}

.c-list-description__term {
  font-weight: 700;
  margin-bottom: 0;
}

.c-list-description__details {
  display: block;
}

.c-list-description__details p {
  display: block;
  margin-bottom: 0;
}
.c-list-description__details p:last-child {
  margin-bottom: 0;
}

.c-list-columned {
  column-gap: 24px;
}
@media only screen and (min-width: 768px) {
  .c-list-columned {
    column-count: 2;
  }
}
@media only screen and (min-width: 876px) {
  .c-list-columned {
    column-count: 3;
  }
}

.c-list-group {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

.c-list-group__item > a.c-list-group__link--bold {
  font-weight: 700;
}

.c-list-group--xs .c-list-group__item {
  padding: 4px 0;
}

.c-list-group--sm .c-list-group__item {
  padding: 8px 0;
}

.c-list-group--md .c-list-group__item {
  padding: 16px 0;
}

.c-list-group--lg .c-list-group__item {
  padding: 24px 0;
}

.c-list-group--bordered .c-list-group__item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.c-list-group--flush .c-list-group__item:first-child {
  padding-top: 0;
}

.c-list-group--flush .c-list-group__item:last-child {
  padding-bottom: 0;
}

.c-list-bullets ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) {
  list-style-type: none;
  list-style-position: initial;
  padding: 0;
  margin-bottom: 48px;
}

.c-list-bullets ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list):has(+ .u-mt-24) {
  margin-bottom: 0;
}

.c-list-bullets ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) li {
  position: relative;
  padding-left: 24px;
  line-height: 1.8;
  margin-bottom: 0.5em;
}

.c-list-bullets ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) li > ul {
  margin-top: 0.5em;
  margin-bottom: 0;
}

.c-list-bullets--no-spacing ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list),
.c-list-bullets--no-spacing ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) li {
  margin-bottom: 0;
}

.c-list-bullets--no-spacing ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) li > ul {
  margin-top: 0;
}

.c-list-bullets ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) li::before {
  position: absolute;
  content: "";
  left: 0;
  top: calc(0.9em - 5px);
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' fill='%233a9bd6'%3E%3Ccircle cx='50' cy='50' r='50'/%3E%3C/svg%3E");
  width: 10px;
  height: 10px;
}

.c-list-bullets ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) li > ul > li:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='49.4' cy='49.4' r='44.4' stroke='%233a9bd6' stroke-width='10' fill='none'/%3E%3C/svg%3E");
}

.c-list-bullets ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) li ol {
  margin: 1em 0 16px 0;
  padding-inline-start: 1em;
}
.c-list-bullets ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) li ol li {
  padding-left: 0.5em;
}
.c-list-bullets ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) li ol li:before {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .c-list-bullets ul:not(.c-list-bullets--hide):not(.app-journal-editorial__editor-list):not(.c-author-list):not(.eds-c-pagination):not(.c-pagination):not(.app-collections-list__list):not(.app-journal-latest-issue__editors-list):not(.app-info-presentation-box):not(.app-editorial-board-page-editors-list):not(.app-avatar-card__details-list) li ol {
    padding-inline-start: 2em;
  }
}

/**
 * Meta
 * Default branding for component
 */
.c-meta {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  color: #000000;
  line-height: 1.5;
}

.c-meta--large {
  font-size: 1.25rem;
}
.c-meta--large .c-meta__item {
  margin-bottom: 20px;
}

.c-meta__item:not(:last-child) {
  border-right: 2px solid #999999;
  padding-right: 8px;
  margin-right: 8px;
  line-height: 1;
  border-color: #c5e0f4;
}

.c-meta__item--spacing:not(:last-child) {
  margin-bottom: 4px;
}

@media only screen and (max-width: 767px) {
  .c-meta__item--block-sm-max {
    display: block;
  }
  .c-meta__item--block-sm-max:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .c-meta__item--block-at-lg {
    display: block;
  }
  .c-meta__item--block-at-lg:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

.c-meta__type {
  text-transform: none;
  font-weight: 700;
}

.c-meta__link {
  color: #222222;
  text-decoration: underline;
}

.c-meta__link:visited {
  color: #222222;
}

.c-refine-by {
  font-size: 16px;
  border: 0;
  margin: 0;
  padding: 0;
}

.c-refine-by__layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px 24px;
}
.c-refine-by__layout > .c-forms__label--wrapper {
  margin-top: 0;
}

@media only screen and (min-width: 480px) {
  .c-refine-by__layout {
    flex-direction: row;
  }
}
.c-refine-by__legend {
  font-weight: 700;
  margin-bottom: 16px;
  white-space: nowrap;
}

@media only screen and (min-width: 480px) {
  .c-refine-by__legend {
    float: left;
    margin-right: 16px;
    margin-bottom: 0;
  }
}
/**
 * @springernature/eds/skip-link
 * Default skin settings
 *
 */
/**
 * @springernature/eds/skip-link
 * A full width link which is hidden for mouse users
 *
 */
.c-skip-link {
  background: #01324b;
  color: white;
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 8px;
  position: absolute;
  width: 100%;
  inset: 0;
  bottom: auto;
  z-index: 9999;
  transform: translateY(-100%);
}
@media (prefers-reduced-motion: reduce) {
  .c-skip-link {
    transition: top 0.3s ease-in-out;
  }
}
@media print {
  .c-skip-link {
    display: none;
  }
}
.c-skip-link:link, .c-skip-link:active, .c-skip-link:visited, .c-skip-link:hover {
  color: white;
}
.c-skip-link:focus {
  transform: translateY(0%);
}

/**
 * Status Message
 * Default branding for component
 */
.c-status-message {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: clamp(0.875rem, 4vw, 1rem);
  font-weight: 400;
  position: relative;
}
.c-status-message *:last-child {
  margin-bottom: 0px;
}
.c-status-message a {
  color: #000000;
}

.c-status-message--bold {
  font-weight: 700;
}

.c-status-message--boxed {
  background-color: white;
  border: 1px solid #dadada;
  border-radius: 8px;
  line-height: 1.4;
  padding: 16px;
  box-shadow: 0 0 5px 0 rgba(51, 51, 51, 0.1);
  overflow: hidden;
}

.c-status-message--banner {
  background-color: white;
  line-height: 1.4;
  padding: 16px 0 16px 0;
}

.c-status-message--banner .c-status-message__container {
  justify-content: center;
  padding: 0 16px;
}

.c-status-message--sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}

.c-status-message__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-status-message__icon {
  width: 21px;
  height: 21px;
  margin-right: 8px;
  flex-shrink: 0;
  align-self: flex-start;
}

.c-status-message__message :first-child,
.c-status-message__message :last-child {
  margin-top: 0;
}

.c-status-message__icon--top {
  align-self: flex-start;
}

.c-status-message--info .c-status-message__icon {
  color: #0070a8;
}

.c-status-message--banner.c-status-message--info {
  border-bottom: 4px solid #0070a8;
}

.c-status-message--boxed.c-status-message--info .c-status-message__bottom-border {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #0070a8;
  height: 4px;
  width: 100%;
}

.c-status-message--info .c-status-message__icon {
  fill: #0070a8;
}

.c-status-message--error .c-status-message__icon {
  color: #be1818;
}

.c-status-message--banner.c-status-message--error {
  border-bottom: 4px solid #be1818;
}

.c-status-message--boxed.c-status-message--error .c-status-message__bottom-border {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #be1818;
  height: 4px;
  width: 100%;
}

.c-status-message--error .c-status-message__icon {
  fill: #be1818;
}

.c-status-message--success .c-status-message__icon {
  color: #00a69d;
}

.c-status-message--banner.c-status-message--success {
  border-bottom: 4px solid #00a69d;
}

.c-status-message--boxed.c-status-message--success .c-status-message__bottom-border {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #00a69d;
  height: 4px;
  width: 100%;
}

.c-status-message--success .c-status-message__icon {
  fill: #00a69d;
}

.c-status-message--warning .c-status-message__icon {
  color: #f58220;
}

.c-status-message--banner.c-status-message--warning {
  border-bottom: 4px solid #f58220;
}

.c-status-message--boxed.c-status-message--warning .c-status-message__bottom-border {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f58220;
  height: 4px;
  width: 100%;
}

.c-status-message--warning .c-status-message__icon {
  fill: #f58220;
}

/**
 * @springernature/elements/author-list
 * Default skin settings
 *
 */
.c-author-list {
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #222222;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 0;
}
.c-author-list > li {
  display: inline;
}
.c-author-list > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.c-author-list > li:not(:only-child):last-child::before {
  content: " & ";
}

.c-author-list--compact {
  line-height: 1.4;
  font-size: 0.875rem;
}

.c-author-list--truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.js .c-author-list {
  display: inline;
}
.js .c-author-list__hide {
  display: none;
  visibility: hidden;
}
.js .c-author-list__hide:first-child + * {
  margin-block-start: 0;
}

.eds-c-button {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: relative;
  text-decoration: none;
  text-align: center;
  width: 100%;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem 1.5rem;
  transition: all 0.2s;
  border-radius: 32px;
  font-weight: 700;
}
.eds-c-button span {
  vertical-align: middle;
}
.eds-c-button svg {
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
}
.eds-c-button svg:first-child {
  margin-right: 8px;
}
.eds-c-button svg:last-child {
  margin-left: 8px;
}

@media only screen and (min-width: 480px) {
  .eds-c-button {
    width: auto;
  }
}
.eds-c-button--primary {
  color: #ffffff;
  background-color: #025e8d;
  background-image: none;
  border: 2px solid transparent;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--primary svg {
  fill: currentColor;
}
.eds-c-button--primary:visited {
  color: #ffffff;
}
.eds-c-button--primary:hover {
  border: 2px solid #025e8d;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--primary:focus {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 2px #025e8d;
  text-decoration: none;
}
.eds-c-button--primary:active {
  border: 2px solid #01324b;
  box-shadow: none;
  text-decoration: none;
  color: #01324b;
  background-color: #ffffff;
}
.eds-c-button--primary:hover, .eds-c-button--primary:focus {
  color: #025e8d;
  background-color: #ffffff;
  background-image: none;
}
.eds-c-button--primary:hover svg path, .eds-c-button--primary:focus svg path {
  fill: #025e8d;
}

.eds-c-button--secondary {
  color: #025e8d;
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #025e8d;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--secondary svg {
  fill: currentColor;
}
.eds-c-button--secondary:visited {
  color: #025e8d;
}
.eds-c-button--secondary:hover {
  border: 2px solid #025e8d;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--secondary:focus {
  border: 2px solid #ffffff;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--secondary:active {
  border: 2px solid #01324b;
  box-shadow: none;
  text-decoration: none;
  color: #ffffff;
  background-color: #01324b;
}
.eds-c-button--secondary:hover, .eds-c-button--secondary:focus {
  color: #ffffff;
  background-color: #025e8d;
  background-image: none;
}
.eds-c-button--secondary:hover svg path, .eds-c-button--secondary:focus svg path {
  fill: #ffffff;
}

.eds-c-button--tertiary {
  color: #025e8d;
  background-color: #f0f7fc;
  background-image: none;
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
.eds-c-button--tertiary span {
  vertical-align: baseline;
}
.eds-c-button--tertiary svg {
  fill: currentColor;
}
.eds-c-button--tertiary:visited {
  color: #025e8d;
}
.eds-c-button--tertiary:hover {
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.eds-c-button--tertiary:focus {
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
}
.eds-c-button--tertiary:active {
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
  color: #01324b;
  background-color: #f0f7fc;
}
.eds-c-button--tertiary:hover, .eds-c-button--tertiary:focus {
  color: #025e8d;
  background-color: #f0f7fc;
  background-image: none;
}
.eds-c-button--tertiary:hover svg path, .eds-c-button--tertiary:focus svg path {
  fill: #025e8d;
}

.eds-c-button--small {
  font-size: 0.875rem;
  padding: 0.25rem 1rem;
}
.eds-c-button--small svg {
  width: 1rem;
  height: 1rem;
}

/**
 * Card
 * Default branding for component
 */
.eds-c-card-composable {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  color: #222222;
  background-color: #ffffff;
  overflow: hidden;
  font-family: inherit;
  border-radius: 8px;
  border: 1px solid #c5e0f4;
}
.eds-c-card-composable.eds-c-card-composable--faux-link {
  position: relative;
}
.eds-c-card-composable.eds-c-card-composable--faux-link:hover {
  background-color: #c5e0f4;
}
.eds-c-card-composable.eds-c-card-composable--faux-link:hover .eds-c-card-composable__sidebar--with-separator::before {
  background-color: #ffffff;
}
.eds-c-card-composable.eds-c-card-composable--faux-link:hover .eds-c-card-composable__icon-container {
  background-color: #ffffff;
}
.eds-c-card-composable.eds-c-card-composable--faux-link:hover .eds-c-card-composable__metadata-list-horizontal div:not(:last-child) {
  border-bottom: 1px solid #ffffff;
}

.eds-c-card-composable--highlight-default {
  border-top: 10px solid #dadada;
}

.eds-c-card-composable--highlight-action {
  border-top: 10px solid #0088cc;
}

.eds-c-card-composable__picture,
.eds-c-card-composable__image {
  flex: 0 0 auto;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  margin: 0;
}
.eds-c-card-composable__picture img,
.eds-c-card-composable__image img {
  width: 100%;
  height: inherit;
  object-fit: cover;
}
@supports (aspect-ratio: 1/1) {
  .eds-c-card-composable__picture img,
  .eds-c-card-composable__image img {
    padding-bottom: 0;
    aspect-ratio: var(--eds-c-card-composable--image-aspect-ratio, 16/9);
  }
}

.eds-c-card-composable__summary {
  line-height: 1.5;
}
.eds-c-card-composable__summary.eds-c-card__summary--truncate-text {
  max-width: 650px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.eds-c-card-composable__label,
p.eds-c-card-composable__label {
  margin-bottom: 0.25rem;
}

.eds-c-card-composable__container {
  display: flex;
  flex-direction: column;
}

.eds-c-card-composable__container--with-sidebar {
  flex-direction: row;
  flex-grow: 1;
}

.eds-c-card-composable__body {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
  padding: 16px;
}

.eds-c-card-composable__title {
  font-size: 1.125rem;
  margin-top: 0;
  margin-bottom: 8px;
  max-width: 650px;
}
.eds-c-card-composable__title > a {
  color: #222222;
}
.eds-c-card-composable__title > a:visited {
  color: #222222;
}

.eds-c-card-composable--faux-link .eds-c-card-composable__title > a {
  text-decoration: none;
}
.eds-c-card-composable--faux-link .eds-c-card-composable__title > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.eds-c-card-composable__sidebar {
  padding: 0 16px 16px 16px;
}

.eds-c-card-composable__sidebar--with-separator {
  display: flex;
  padding: 0 16px 16px 16px;
  flex-direction: column;
  gap: 8px;
}
.eds-c-card-composable__sidebar--with-separator::before {
  content: "";
  display: block;
  height: 1px;
  flex: 0 2 auto;
  background: #c5e0f4;
}

.eds-c-card-composable__icon-container {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f0f7fc;
  padding: 16px;
  margin-right: 16px;
  color: #0088cc;
}

.eds-c-card-composable__metadata-list {
  margin: 0;
  line-height: 1.5;
}
.eds-c-card-composable__metadata-list div:not(:last-child) {
  margin-bottom: 16px;
}
.eds-c-card-composable__metadata-list dt {
  margin-bottom: 4px;
}
.eds-c-card-composable__metadata-list dd {
  font-weight: 700;
  white-space: nowrap;
}

.eds-c-card-composable__metadata-list-horizontal {
  margin: 0;
  flex-grow: 1;
}
.eds-c-card-composable__metadata-list-horizontal div {
  display: flex;
  justify-content: space-between;
}
.eds-c-card-composable__metadata-list-horizontal div:not(:last-child) {
  border-bottom: 1px solid #c5e0f4;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.eds-c-card-composable__metadata-list-horizontal dt {
  margin-bottom: 0;
}
.eds-c-card-composable__metadata-list-horizontal dd {
  font-weight: 700;
}
.eds-c-card-composable__metadata-list-horizontal dd:not(:last-child) {
  margin-bottom: 16px;
}

.eds-c-card-composable__journal-cover img {
  border-radius: 8px;
  max-width: 100%;
}

.eds-c-card-composable__body-journal {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr auto;
  gap: 16px 16px;
  grid-template-areas: "eds-c-card-composable__journal-cover eds-c-card-composable__journal-title" "eds-c-card-composable__journal-description eds-c-card-composable__journal-description";
}

.eds-c-card-composable__journal-cover {
  grid-area: eds-c-card-composable__journal-cover;
}

.eds-c-card-composable__journal-title {
  grid-area: eds-c-card-composable__journal-title;
}

.eds-c-card-composable__journal-description {
  grid-area: eds-c-card-composable__journal-description;
}

@media only screen and (min-width: 480px) {
  .eds-c-card-composable--horizontal.eds-c-card-composable--highlight-default {
    border-top: 1px solid #c5e0f4;
    border-left: 10px solid #dadada;
  }
  .eds-c-card-composable--horizontal.eds-c-card-composable--highlight-action {
    border-top: 1px solid #c5e0f4;
    border-left: 10px solid #0088cc;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__container--to-sidebar {
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__sidebar {
    width: 280px;
    padding: 16px 16px 16px 0;
    flex-grow: 1;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__sidebar--with-separator {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__sidebar--with-separator::before {
    width: 1px;
    height: auto;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__title {
    font-size: 1.5rem;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__body {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 400px;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__body-journal {
    grid-template-columns: 115px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "eds-c-card-composable__journal-cover eds-c-card-composable__journal-title" "eds-c-card-composable__journal-cover eds-c-card-composable__journal-description";
    gap: 0 16px;
    flex-basis: 0;
    flex-grow: 999;
    min-width: 400px;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__journal-cover {
    grid-area: eds-c-card-composable__journal-cover;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__journal-title {
    grid-area: eds-c-card-composable__journal-title;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__journal-description {
    grid-area: eds-c-card-composable__journal-description;
  }
}
.eds-c-footer {
  font-size: 1rem;
  background-color: #01324b;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-top: 32px;
  padding-bottom: 32px;
  color: #ffffff;
  line-height: 1.4;
}

.eds-c-footer__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
@media print {
  .eds-c-footer__container {
    display: none;
  }
}

.eds-c-footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 32px;
}
.eds-c-footer__links li {
  display: inline-block;
}
.eds-c-footer__links li:not(:last-child) {
  margin-right: 24px;
}

.eds-c-footer__user {
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 24px;
}

.eds-c-footer__user-info {
  margin-bottom: 16px;
  margin-top: 0;
  line-height: 1.4;
}

.eds-c-footer__legal {
  color: #ffffff;
  font-size: 1rem;
  margin-top: 4px;
  margin-bottom: 0;
}

.eds-c-footer__link {
  line-height: 1.4;
  color: inherit;
}
.eds-c-footer__link.visited, .eds-c-footer__link:visited {
  color: inherit;
}
.eds-c-footer__link.hover, .eds-c-footer__link:hover {
  color: inherit;
}

.eds-c-footer__link > img {
  vertical-align: middle;
}

button.eds-c-footer__link {
  border: none;
  background: none;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: inherit;
  padding: 0;
  text-align: left;
}

.eds-c-footer__button-text {
  color: #025e8d;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
.eds-c-footer__button-text.visited, .eds-c-footer__button-text:visited {
  color: #01324b;
}
.eds-c-footer__button-text.hover, .eds-c-footer__button-text:hover {
  color: #01324b;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.eds-c-footer__button-text.active, .eds-c-footer__button-text:active {
  color: #025e8d;
}
.eds-c-footer__button-text.focus, .eds-c-footer__button-text:focus {
  outline: 4px solid #0088cc;
}
.eds-c-footer__button-text {
  color: inherit;
}
.eds-c-footer__button-text.visited, .eds-c-footer__button-text:visited {
  color: inherit;
}
.eds-c-footer__button-text.hover, .eds-c-footer__button-text:hover {
  color: inherit;
}

.eds-c-footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.eds-c-footer__grid {
  display: flex;
  flex-wrap: wrap;
}
@supports (display: grid) {
  .eds-c-footer__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 32px;
  }
}

.eds-c-footer__group {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .eds-c-footer__group {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@supports (display: grid) {
  .eds-c-footer__group {
    padding-right: 0;
    max-width: none;
    margin-bottom: 0;
  }
}

.eds-c-footer__group--separator {
  border-bottom: 2px solid #ffffff;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.eds-c-footer__heading {
  color: #ffffff;
  margin-bottom: 16px;
  font-weight: bold;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.eds-c-footer__heading--visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.eds-c-footer__item:not(:last-child) {
  margin-bottom: 16px;
}

.eds-c-header {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  background-color: #ffffff;
  padding: 8px 0 0;
  border-bottom: 2px solid #01324b;
  font-size: 1rem;
  line-height: 1.5;
}

.eds-c-header__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto 8px;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px 16px;
}

.eds-c-header__nav {
  border-top: 2px solid #c5e0f4;
  padding-top: 4px;
  position: relative;
}

.eds-c-header__nav-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 0 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.eds-c-header__nav-container > *:not(:last-child) {
  margin-right: 32px;
}

.eds-c-header__link-container {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  gap: 8px 16px;
  justify-content: space-between;
}

.eds-c-header__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.eds-c-header__list-item {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  font-weight: bold;
  padding: 8px;
}
.eds-c-header__list-item:not(:last-child) {
  border-bottom: 2px solid #c5e0f4;
}

.eds-c-header__item {
  color: inherit;
}

@media only screen and (min-width: 768px) {
  .eds-c-header__item--menu {
    display: none;
    visibility: hidden;
  }
  .eds-c-header__item--menu:first-child + * {
    margin-block-start: 0;
  }
}

.eds-c-header__item--inline-links {
  display: none;
  visibility: hidden;
}
.eds-c-header__item--inline-links:first-child + * {
  margin-block-start: 0;
}
@media only screen and (min-width: 768px) {
  .eds-c-header__item--inline-links {
    display: flex;
    gap: 16px;
    visibility: visible;
  }
}

.eds-c-header__item--divider:before {
  content: "";
  position: absolute;
  border-left: 2px solid #c5e0f4;
  height: calc(100% - 16px);
  top: 8px;
  margin-left: -15px;
}

.eds-c-header__brand {
  padding: 16px 8px;
}
.eds-c-header__brand a {
  display: block;
  line-height: 1;
  text-decoration: none;
}
.eds-c-header__brand img {
  height: 1.5rem;
  width: auto;
}

.eds-c-header__link {
  display: inline-block;
  padding: 16px 8px;
  color: inherit;
  font-weight: bold;
  text-decoration-color: transparent;
  position: relative;
  word-break: normal;
  white-space: nowrap;
}
.eds-c-header__link:visited {
  color: inherit;
}
.eds-c-header__link:hover {
  color: inherit;
  text-decoration-color: inherit;
}
.eds-c-header__link:not(.eds-c-header__expander .eds-c-header__link):hover {
  text-decoration-color: transparent;
}
.eds-c-header__link:not(.eds-c-header__expander .eds-c-header__link):hover::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.eds-c-header__link--static {
  flex: 0 0 auto;
}

.eds-c-header__link.is-open {
  color: #025e8d;
}
.eds-c-header__link.is-open::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.eds-c-header__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  font-size: 1.5rem;
}
.eds-c-header__icon + * {
  margin-left: 8px;
}

.eds-c-header__expander {
  background-color: #f0f7fc;
}

.eds-c-header__search {
  display: block;
  padding: 24px 0;
}
@media only screen and (min-width: 768px) {
  .eds-c-header__search {
    max-width: 70%;
  }
}

.eds-c-header__search-container {
  position: relative;
}

.eds-c-header__search-label {
  display: inline-block;
  color: inherit;
  font-weight: bold;
  margin-bottom: 8px;
}

.eds-c-header__search-input {
  border: 1px solid black;
  width: 100%;
  padding: 8px 48px 8px 8px;
  background-color: white;
}

.eds-c-header__search-button {
  background-color: transparent;
  border: none;
  color: inherit;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  right: 0;
}

.has-tethered.eds-c-header__expander {
  margin-top: -2px;
  width: 100%;
  top: 100%;
  z-index: 10;
  left: 0;
  border-bottom: 2px solid #01324b;
}

@media only screen and (min-width: 768px) {
  .has-tethered.eds-c-header__expander--menu {
    display: none;
    visibility: hidden;
  }
  .has-tethered.eds-c-header__expander--menu:first-child + * {
    margin-block-start: 0;
  }
}

.has-tethered.eds-c-header__expander--account {
  transform: translateY(8px);
  width: auto;
  left: auto;
  position: absolute;
  right: 0;
  bottom: auto;
  margin-top: 0;
}

.has-tethered .eds-c-header__heading {
  display: none;
  visibility: hidden;
}
.has-tethered .eds-c-header__heading:first-child + * {
  margin-block-start: 0;
}
.has-tethered .eds-c-header__search {
  margin: auto;
}

.eds-c-header__heading {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 16px 16px 0;
}

.eds-c-pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  padding: 32px 0;
  line-height: 1.4;
  gap: 16px 0;
}

@media only screen and (min-width: 480px) {
  .eds-c-pagination {
    padding: 32px 16px;
  }
}
.eds-c-pagination__item {
  margin-right: 8px;
}

.eds-c-pagination__item--prev {
  margin-right: 16px;
}
.eds-c-pagination__item--prev .eds-c-pagination__link {
  padding: 16px 8px;
}

.eds-c-pagination__item--next {
  margin-left: 8px;
}
.eds-c-pagination__item--next .eds-c-pagination__link {
  padding: 16px 8px;
}

.eds-c-pagination__item:last-child {
  margin-right: 0;
}

.eds-c-pagination__link {
  align-items: center;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  margin: 0;
  position: relative;
  padding: 16px 24px;
  text-align: center;
  transition: all 0.2s;
  color: #222222;
}

.eds-c-pagination__link:visited {
  color: #222222;
}

.eds-c-pagination__link--disabled {
  color: #555555;
  border-color: #555555;
  cursor: default;
}

.eds-c-pagination__link--active {
  color: #ffffff;
  background-color: #01324b;
  background-image: none;
  border-radius: 8px;
}

.eds-c-pagination__link--active:hover,
.eds-c-pagination__link--active:focus,
.eds-c-pagination__link--active:visited {
  color: #ffffff;
}

.eds-c-pagination__link-container {
  display: flex;
  align-items: center;
}

.eds-c-pagination__icon {
  fill: #222222;
  width: 1.5rem;
  height: 1.5rem;
}

.eds-c-pagination__icon--disabled {
  fill: #555555;
}

.eds-c-pagination__visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

/**
 * Status Message
 * Default branding for component
 */
.eds-c-status-message {
  display: flex;
  gap: 0.5rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  position: relative;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #999999;
  line-height: 1.4;
  padding: 16px;
  padding-bottom: 12px;
}
.eds-c-status-message *:last-child {
  margin-bottom: 0;
}

.eds-c-status-message__message a {
  color: inherit;
}

.eds-c-status-message__text {
  padding-right: 12px;
  margin-top: 2px;
}

.eds-c-status-message__text h5 {
  margin-top: 0;
}

.eds-c-status-message__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  margin-right: 8px;
}

.eds-c-status-message__icon--top {
  align-self: flex-start;
}

.eds-c-status-message__dismiss-button {
  position: absolute;
  border: none;
  background: none;
  right: 8px;
  cursor: pointer;
}

.eds-c-status-message__dismiss-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 24px;
  height: 24px;
  color: #555555;
}

.eds-c-status-message__title {
  font-weight: 700;
  margin-bottom: 8px;
}

.eds-c-status-message--info .eds-c-status-message__icon {
  color: #0070a8;
}

.eds-c-status-message--info {
  border-bottom: 4px solid #0070a8;
}

.eds-c-status-message--error .eds-c-status-message__icon {
  color: #be1818;
}

.eds-c-status-message--error {
  border-bottom: 4px solid #be1818;
}

.eds-c-status-message--success .eds-c-status-message__icon {
  color: #00a69d;
}

.eds-c-status-message--success {
  border-bottom: 4px solid #00a69d;
}

.eds-c-status-message--warning .eds-c-status-message__icon {
  color: #f58220;
}

.eds-c-status-message--warning {
  border-bottom: 4px solid #f58220;
}

/**
 * @springernature/elements/layout-stack
 * Default skin settings
 *
 */
.l-stack > * {
  margin: 0;
}

.l-stack > * + * {
  margin-top: 1.5em; /* ← core browser fallback */
  margin-top: var(--stack--gap, 1.5em);
}

@supports (gap: 1.5em) {
  .l-stack > * {
    margin: 0;
  }
  .l-stack {
    display: flex;
    flex-direction: column;
    gap: var(--stack--gap, 1.5em);
  }
}
/**
 * @springernature/elements/layout-grid
 * Default skin settings
 *
 */
/* ↓ Necessary since non-Dart Sass chokes
on the min() function */
.l-grid {
  list-style: none;
  padding: 0;
}

.l-grid > * + * {
  margin-top: 1em;
  margin-top: var(--grid--gap, 1em);
}

@supports (display: grid) {
  .l-grid > * {
    margin: 0;
  }
  .l-grid {
    display: grid;
    gap: 1em;
    gap: var(--grid--gap, 1em);
    grid-template-columns: repeat(auto-fit, minmax(var(--grid--basis, min(300px, 100%)), 1fr));
  }
}
/**
 * @springernature/elements/layout-with-sidebar
 * Default skin settings
 *
 */
.l-with-sidebar {
  display: flex;
  flex-wrap: wrap;
}

.l-with-sidebar > * {
  margin: 0;
}

.l-with-sidebar__sidebar {
  flex-grow: 1;
  flex-basis: 400px; /* fallback static value for IE */
  flex-basis: var(--with-sidebar--basis, 400px);
}

.l-with-sidebar > :not(.l-with-sidebar__sidebar) {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 50%; /* fallback static value for IE */
  min-width: var(--with-sidebar--min, 50%);
}

.l-with-sidebar > :first-child {
  padding-right: 1em; /* gap for IE11 (so desktop) */
}

@supports (gap: 1em) {
  .l-with-sidebar > :first-child {
    padding-right: 0;
  }
  .l-with-sidebar {
    gap: var(--with-sidebar--gap, 1em);
  }
}
.app-about-this-journal {
  font-size: 0.875rem;
}

.app-about-this-journal a {
  color: inherit;
}

.app-about-this-journal a:visited {
  color: inherit;
}

@media only screen and (min-width: 480px) {
  .app-about-this-journal {
    font-size: 1rem;
  }
}
.app-about-this-journal .c-list-description,
.app-about-this-journal .c-list-columned {
  font-size: 0.875rem;
}

.app-about-this-journal .c-list-description__item {
  margin-bottom: 32px;
}

.app-about-this-journal .c-list-description__item:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 480px) {
  .app-about-this-journal .c-list-description,
  .app-about-this-journal .c-list-columned {
    font-size: 1rem;
  }
  .app-about-this-journal .c-list-description__item {
    margin-bottom: 48px;
  }
}
.app-accordion {
  width: 100%;
}

.app-accordion__button-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.app-accordion__button {
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 100%;
  flex-grow: 1;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-top: 1px solid #c5e0f4;
  border-bottom: 1px solid #c5e0f4;
}

@media only screen and (min-width: 480px) {
  .app-accordion__button {
    border-left: 1px solid #c5e0f4;
    border-right: 1px solid #c5e0f4;
  }
}
.app-accordion + .app-accordion .app-accordion__button,
li + li > .app-accordion .app-accordion__button {
  border-top: none;
}

.app-accordion__button.is-open {
  border-left: none;
  padding-left: 1rem;
}

.app-accordion__button.is-open + .app-accordion__strip {
  flex-grow: 0;
  width: 8px;
  background-color: #025e8d;
}

.app-accordion__icon {
  width: 1em;
  height: 1em;
  color: #555555;
  fill: #555555;
}

.app-accordion__button.is-open .app-accordion__icon {
  color: #025e8d;
  fill: #025e8d;
  transform: rotate(180deg);
}

.app-accordion__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.app-accordion__title {
  display: inline-block;
  color: #555555;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
}

.app-accordion__button.is-open .app-accordion__title {
  color: #025e8d;
}

.app-accordion__content {
  background-color: #f0f7fc;
  padding: 1.5rem;
  border-bottom: 1px solid #c5e0f4;
}

.app-accordion__content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-accordion__content {
  font-size: 0.875rem;
}
.app-accordion__content h1,
.app-accordion__content h2,
.app-accordion__content h3,
.app-accordion__content h4,
.app-accordion__content h5,
.app-accordion__content h6 {
  font-size: 1rem;
}
.app-accordion__content h1:first-child,
.app-accordion__content h2:first-child,
.app-accordion__content h3:first-child,
.app-accordion__content h4:first-child,
.app-accordion__content h5:first-child,
.app-accordion__content h6:first-child {
  margin-top: 0;
}
.app-accordion__content li + li {
  margin-top: 1rem;
}
.app-accordion__content a {
  color: #222222;
  line-height: 1.5;
}
.app-accordion__content a:active {
  font-weight: 700;
  outline: 4px solid #0088cc;
}

.app-accordion__content.app-accordion__content--grouped > ul > li + li {
  margin-top: 2rem;
}

.app-avatar-card {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  row-gap: 8px;
  width: 100%;
  background-color: #ffffff;
  padding: 16px 0;
  border-top: 1px solid #c5e0f4;
  border-bottom: 1px solid #c5e0f4;
}

.app-avatar-card + .app-avatar-card {
  border-top: none;
}

.app-avatar-card .app-avatar-card__image,
.app-avatar-card .app-avatar-card__avatar {
  grid-area: picture;
}

.app-avatar-card__heading,
.app-show-more-show-less {
  grid-area: name;
}

.app-avatar-card__details {
  grid-area: details;
  grid-column-start: 2;
}
.app-avatar-card__details a {
  display: block;
  color: #222222;
  line-height: 1.5;
  line-break: anywhere;
}
.app-avatar-card__details a + a {
  margin-top: 8px;
}

.app-avatar-card > .app-avatar-card__image,
.app-avatar-card > .app-avatar-card__avatar {
  flex-shrink: 0;
}

.app-avatar-card__image {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
}

.app-avatar-card__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: #f0f7fc;
  border-radius: 50%;
  position: relative;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzY2NjY2NiIgaWQ9ImVkcy1pLXVzZXItc2luZ2xlLW1lZGl1bSI+PHBhdGggZD0iTTEyIDFhNSA1IDAgMSAxIDAgMTAgNSA1IDAgMCAxIDAtMTBabTAgMmEzIDMgMCAxIDAgMCA2IDMgMyAwIDAgMCAwLTZabS0uNDA2IDkuMDA4YTguOTY1IDguOTY1IDAgMCAxIDYuNTk2IDIuNDk0QTkuMTYxIDkuMTYxIDAgMCAxIDIxIDIxLjAyNVYyMmExIDEgMCAwIDEtMSAxSDRhMSAxIDAgMCAxLTEtMXYtLjk4NWMuMDUtNC44MjUgMy44MTUtOC43NzcgOC41OTQtOS4wMDdabS4zOSAxLjk5Mi0uMjk5LjAwNmMtMy42My4xNzUtNi41MTggMy4xMjctNi42NzggNi43NzVMNSAyMWgxMy45OThsLS4wMDktLjI2OGE3LjE1NyA3LjE1NyAwIDAgMC0xLjk3LTQuNTczbC0uMjE0LS4yMTNBNi45NjcgNi45NjcgMCAwIDAgMTEuOTg0IDE0WiIvPjwvc3ZnPgo=");
  background-position: 50% 50%;
  background-size: 60%;
  background-repeat: no-repeat;
}

/*.app-avatar-card__avatar {
	background-color: #f0f7fc;
	border-radius: 50%;
}*/
.app-avatar-card__list,
.app-avatar-card__details-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-avatar-card__text-container p {
  font-size: clamp(0.875rem, 4vw, 1rem);
  margin-bottom: 0;
}

.app-avatar-card__description {
  font-size: clamp(0.875rem, 4vw, 1rem);
  margin: 8px 0 0 0;
}

.app-avatar-card__role {
  font-size: clamp(0.875rem, 4vw, 1rem);
  margin: 8px 0 0 0;
}

.app-avatar-card__details-list {
  list-style: none;
  margin: 0;
  padding: 0;
  grid-area: details;
  display: none;
  border-left: 8px solid #c5e0f4;
  font-size: 0.875rem;
  padding-left: 8px;
  margin-top: 24px;
}
.app-avatar-card__details-list .u-text-s {
  font-size: 0.875rem;
}

.app-avatar-card__details-list li {
  margin-bottom: 16px;
}

.app-avatar-card__details-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-avatar-card__details-list ul li {
  margin: 8px;
}

.app-avatar-card__details-list li:last-child {
  margin-bottom: 0;
}

button.app-show-more-show-less--expanded + .app-avatar-card__details-list {
  display: block;
}

.app-avatar-card .app-show-more-show-less span {
  font-size: 0.875rem;
}

.app-avatar-card__heading {
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
}

.app-eb-orcid-icon {
  margin-top: 3px;
}

@media only screen and (min-width: 480px) {
  .app-avatar-card {
    display: inline-grid;
    grid-template-columns: 64px auto;
    grid-template-rows: 1fr;
    /* stylelint-disable declaration-colon-space-after */
    /* stylelint-disable indentation */
    grid-template-areas: "picture name";
    /* stylelint-enable declaration-colon-space-after */
    /* stylelint-enable indentation */
    gap: 8px 16px;
    font-size: 1rem;
    padding: 24px 0;
  }
  .app-avatar-card--with-details {
    /* stylelint-disable declaration-colon-space-after */
    /* stylelint-disable indentation */
    grid-template-areas: "picture name" "picture details";
    /* stylelint-enable declaration-colon-space-after */
    /* stylelint-enable indentation */
  }
  .app-avatar-card__heading {
    font-size: 1.125rem;
  }
  .app-avatar-card__heading--center {
    align-self: center;
  }
  .app-avatar-card__description.show-more-show-less {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
  .app-avatar-card .app-show-more-show-less span {
    font-size: 1rem;
  }
  .app-avatar-card__details-list {
    font-size: 1rem;
    padding-left: 16px;
  }
  .app-avatar-card__details-list .u-text-s {
    font-size: initial;
  }
  .app-avatar-card__details-list li {
    margin-bottom: 24px;
  }
}
@media only screen and (min-width: 1024px) {
  ul li:first-child .app-avatar-card {
    margin-top: 0;
  }
  .app-avatar-card__details.app-show-more-show-less--expanded {
    font-size: 1rem;
  }
}
.app-card-collection {
  position: relative;
  display: flex;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #fff;
  padding: 16px;
  border: 1px solid #c5e0f4;
  border-top: 10px solid #3c9cd7;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
}

.app-card-collection__main {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
  padding-bottom: 16px;
}

.app-card-collection__main + .app-card-collection__side {
  border-top: 1px solid #c5e0f4;
}

.app-card-collection__side {
  padding-top: 16px;
}

.app-card-collection__content {
  font-weight: 400;
}

.app-card-collection__text {
  line-height: 1.7;
}

.app-card-collection__text p {
  margin: 0;
}

.app-card-collection__heading {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1.25rem;
}

.app-card-collection__heading a {
  color: #000000;
  line-height: 1.5;
}
.app-card-collection__heading a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.app-card-collection__description-list {
  margin: 0;
  font-size: 0.875rem;
  margin-bottom: 16px;
}

.app-card-collection__description-list:last-of-type {
  margin-bottom: 0;
}

.app-card-collection__description-list dd {
  font-weight: 700;
  margin: 0;
}

.app-card-collection__status-message {
  margin-top: 16px;
}

.app-card-collection__status-message svg {
  fill: #025e8d;
  margin-right: 8px;
}

@media only screen and (min-width: 768px) {
  .app-card-collection {
    flex-direction: row;
    border-left: 10px solid #3c9cd7;
    border-top: 1px solid #c5e0f4;
  }
  .app-card-collection--stack {
    flex-direction: column;
    border-left: 1px solid #c5e0f4;
    border-top: 10px solid #3c9cd7;
  }
  .app-card-collection__main {
    padding-right: 16px;
    padding-bottom: 0;
  }
  .app-card-collection__main + .app-card-collection__side {
    border-left: 1px solid #c5e0f4;
    border-top: none;
  }
  .app-card-collection__side {
    min-width: 240px;
    margin-bottom: 0;
    padding-left: 16px;
    padding-top: 0;
  }
}
.app-card-highlight {
  position: relative;
  display: flex;
  padding: 16px;
  background-color: #f0f7fc;
  border-top: 10px solid #3c9cd7;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column-reverse;
  justify-content: space-between;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.app-card-highlight__main {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
}

.app-card-highlight__content {
  font-weight: 400;
}

.app-card-highlight__text {
  line-height: 1.7;
}

.app-card-highlight__text p {
  margin: 0;
}

.app-card-highlight__text ul {
  margin: 0;
}

.app-card-highlight__text + .app-card-highlight__cta {
  display: inline-block;
  margin-top: 8px;
}

.app-card-highlight__cta {
  font-weight: 700;
  color: #025e8d;
}
.app-card-highlight__cta:hover, .app-card-highlight__cta:focus {
  color: #025e8d;
}

.app-card-highlight__cta-icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.app-card-highlight__heading {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1.125rem;
}

.app-card-highlight__heading a {
  color: #000000;
  line-height: 1.5;
}

.app-card-highlight .app-card-highlight__side {
  flex: 1 0 100%;
  margin: -16px -16px 16px -16px;
}

.app-card-highlight .app-card-highlight__side img {
  width: 100%;
  height: auto;
}

.app-card-highlight .app-card-highlight__text img {
  width: auto;
  max-width: 100%;
}

.app-card-highlight .app-card-highlight__text img.float--right {
  float: right;
}

.app-card-highlight .app-card-highlight__text img.float--right {
  float: left;
}

.app-card-highlight__icon {
  fill: #025e8d;
  width: 72px;
  height: 72px;
}

@media only screen and (min-width: 480px) {
  .app-card-highlight {
    flex-direction: row;
    border-left: 10px solid #3c9cd7;
    border-top: none;
  }
  .app-card-highlight__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .app-card-highlight .app-card-highlight__side {
    flex: 0 0 auto;
    align-self: center;
    margin: -16px -16px -16px 16px;
  }
  .app-card-highlight__heading {
    margin-bottom: 0;
  }
  .app-card-highlight .app-card-highlight__side img {
    border-radius: 0 12px 12px 0;
    width: 256px;
    height: 144px;
    aspect-ratio: 16 / 9;
  }
  .app-card-highlight .app-card-highlight__text img {
    max-width: 40%;
  }
  .app-card-highlight__side {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .app-card-highlight--stack {
    flex-direction: column-reverse;
    border-left: none;
    border-top: 10px solid #3c9cd7;
  }
  .app-card-highlight--stack .app-card-highlight__side img {
    border-radius: initial;
  }
  .app-card-highlight__content {
    display: block;
    flex-direction: unset;
    justify-content: unset;
  }
  .app-card-highlight__heading {
    margin-bottom: 8px;
  }
  .app-card-highlight--stack .app-card-highlight__side {
    max-width: none;
    margin: -16px -16px 0 -16px;
  }
  .app-card-highlight--stack .app-card-highlight__heading {
    margin-top: 16px;
  }
}
.app-card-open {
  position: relative;
  border-bottom: 1px solid transparent;
  background-color: #ffffff;
  padding: 24px 0;
  border-top: 1px solid #c5e0f4;
}
.app-card-open a:not(.app-card-open__link) {
  position: relative;
}

.app-card-open--last {
  border-bottom: 1px solid #c5e0f4;
}

.app-card-open__main {
  flex: 1 1 auto;
  word-break: break-word;
  align-self: baseline;
}

.app-card-open__side {
  position: absolute;
  right: 0;
  top: 24px;
  overflow: hidden;
}

.app-card-open--portrait-ratio .app-card-open__side {
  overflow: auto;
}

.app-card-open img {
  border: 1px solid #cfd8dc;
  border-radius: 12px;
  width: 112px;
  aspect-ratio: 16 / 9;
}

.app-card-open.app-card-open--portrait-ratio img {
  border-radius: 0;
  width: 45px;
  aspect-ratio: auto;
}

.app-card-open__heading {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1rem;
}

.app-card-open__subheading::before {
  content: "";
  display: block;
  padding-top: 8px;
}

.app-card-open__link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.app-card-open__heading a {
  line-height: 1.5;
}

.app-card-open__description {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 16px;
}
@media only screen and (min-width: 480px) {
  .app-card-open__description--larger {
    font-size: 1rem;
  }
}

.app-card-open--has-image .app-card-open__heading {
  max-width: calc(100vw - 112px - 16px - 16px - 16px);
  min-height: 58px;
  padding-right: 8px;
}

.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__heading,
.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__description {
  max-width: calc(100vw - 45px - 16px - 16px - 16px);
}
.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__heading {
  min-height: auto;
}
.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__description {
  padding-right: 8px;
}

.app-card-open__collections-list {
  margin-top: 8px;
}

.app-card-open__heading a {
  color: #000000;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.app-card-open__meta {
  margin-top: 4px;
}

.app-card-open__authors,
.app-card-open__journal {
  display: none;
  font-size: 0.875rem;
  line-height: 1.5;
}

.app-card-open__authors a,
.app-card-open__journal a {
  color: #222222;
}

.app-card-open--compact.app-card-open {
  padding: 16px 0;
}

.app-card-open--full-width .app-card-open__main,
.app-card-open--compact .app-card-open__main {
  margin-right: 0;
}

.app-card-open--compact .app-card-open__heading {
  margin-bottom: 8px;
  font-size: 1rem;
}

.app-card-open__text-container {
  font-size: 0;
}

.app-card-open__metadata-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  flex-direction: column;
}
@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list {
    flex-direction: row;
  }
}

.app-card-open__metadata-list div {
  display: flex;
  border-top: 1px solid #c5e0f4;
  min-width: 124px;
  padding: 8px 0;
  justify-content: space-between;
}
@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list div {
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 16px;
    border-top: 0;
  }
  .app-card-open__metadata-list div:first-child {
    padding-left: 0;
  }
}

@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list div + div {
    border-left: 1px solid #c5e0f4;
  }
}

.app-card-open__metadata-list-term {
  font-size: 0.875rem;
}

.app-card-open__metadata-list-description {
  font-weight: 700;
  font-size: 0.875rem;
}
@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list-description {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 480px) {
  .app-card-open {
    display: flex;
    justify-content: space-between;
  }
  .app-card-open__main {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-right: 192px;
  }
  .app-card-open--has-image .app-card-open__main {
    margin-right: 16px;
  }
  .app-card-open__side {
    position: initial;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .app-card-open img {
    width: 192px;
  }
  .app-card-open--compact img {
    width: 112px;
  }
  .app-card-open__heading {
    font-size: 1.125rem;
  }
  .app-card-open--has-image .app-card-open__heading {
    max-width: initial;
    min-height: initial;
  }
  .app-card-open.app-card-open--portrait-ratio img {
    width: auto;
  }
  .app-card-open__heading,
  .app-card-open__description {
    padding-right: 0;
  }
  .app-card-open__authors,
  .app-card-open__journal {
    display: inline;
  }
}
.app-card-service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #025e8d;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 16px 0;
  border-top: 1px solid #c5e0f4;
}

.app-card-service:last-of-type {
  border-bottom: 1px solid #c5e0f4;
}

.app-card-service__link:hover,
.app-card-service__link:focus {
  color: #025e8d;
}

.app-card-service__link-text {
  font-weight: 700;
  line-height: 1.5;
}

.app-card-service__link-icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  fill: #025e8d;
}

.app-card-service__icon-container {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f0f7fc;
  padding: 14px;
}

.app-card-service__icon {
  fill: #0088cc;
  width: 32px;
  height: 32px;
}

.app-card-service__description {
  color: #222222;
  margin-top: 8px;
}

.app-collections-list {
  display: flex;
  gap: 8px;
  font-size: 0.875rem;
  line-height: 1.4;
}

.app-collections-list__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f7fc;
}

.app-collections-list__icon {
  fill: #222222;
  width: 20px;
  height: 20px;
}

.app-collections-list__label {
  font-weight: 700;
  flex-shrink: 0;
}

.app-collections-list__list-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.6125rem;
}

.app-collections-list__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-collections-list__divider {
  display: none;
}

.app-collections-list__list-item {
  margin-top: 8px;
}

.app-collections-list__link {
  display: block;
  color: #222222;
}

.app-collections-list__link:visited {
  color: #222222;
}

.app-content-page {
  font-family: "Merriweather", serif;
  overflow-wrap: unset;
}
.app-content-page a {
  overflow-wrap: anywhere;
}
.app-content-page h1,
.app-content-page h2,
.app-content-page h3,
.app-content-page h4,
.app-content-page h5,
.app-content-page h6 {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.app-content-page h1 {
  font-size: 1.75rem;
  font-size: clamp(1.5rem, 4vw, 1.75rem);
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 clamp(1rem, 2vw, 3rem);
}
.app-content-page h2 {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
  margin: clamp(0.5rem, 0.5vw, 0.75rem) 0 clamp(1rem, 2vw, 2rem);
}
.app-content-page h3 {
  font-size: 1.375rem;
  margin: clamp(0.5rem, 0.5vw, 0.75rem) 0 clamp(1rem, 1.5vw, 1.5rem);
}
.app-content-page h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
  margin: clamp(0.5rem, 0.5vw, 0.75rem) 0 clamp(1rem, 1.5vw, 1.5rem);
}
.app-content-page h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
  margin: clamp(0.5rem, 0.5vw, 0.75rem) 0 clamp(1rem, 1.5vw, 1.5rem);
}
.app-content-page h6 {
  font-size: 1rem;
  font-weight: 700;
  margin: clamp(0.25rem, 1vw, 1rem) 0;
}
.app-content-page .backToTop {
  margin-bottom: 48px;
}
.app-content-page .u-mt-24.u-mb-48:last-of-type {
  margin-bottom: 0;
}

.app-coremedia-content-page {
  overflow-wrap: initial;
}
.app-coremedia-content-page .underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
}
.app-coremedia-content-page .strike {
  text-decoration: line-through;
}
.app-coremedia-content-page .float--none {
  float: none;
}
.app-coremedia-content-page .float--left {
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
}
.app-coremedia-content-page .float--right {
  float: right;
  margin-left: 16px;
  margin-bottom: 16px;
}
.app-coremedia-content-page .align--left {
  text-align: left;
}
.app-coremedia-content-page .align--center {
  text-align: center;
}
.app-coremedia-content-page .align--right {
  text-align: right;
}
.app-coremedia-content-page .section-underline {
  border-bottom: 1px solid #c5e0f4;
  padding-bottom: 8px;
  width: 100%;
}
.app-coremedia-content-page .divider--paragraph {
  border-top: 2px solid #dadada;
  padding: 16px 0 0;
}
.app-coremedia-content-page img {
  border-radius: 10px;
}
.app-coremedia-content-page p img.float--right,
.app-coremedia-content-page p img.float--left,
.app-coremedia-content-page p img.float--none,
.app-coremedia-content-page p img {
  height: auto;
}
@media only screen and (min-width: 480px) {
  .app-coremedia-content-page p img.float--right,
  .app-coremedia-content-page p img.float--left {
    width: auto;
    max-width: 50%;
  }
  .app-coremedia-content-page p:has(> img)::after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: left;
  }
}
.app-coremedia-content-page blockquote {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.5;
  background-color: #f0f7fc;
  background-image: url("/oscar-static/images/icon-quote.svg");
  background-position: 16px 18px;
  background-repeat: no-repeat;
  background-size: 24px;
  margin: 0 0 32px;
  padding: 16px 16px 16px 48px;
}
.app-coremedia-content-page blockquote.float--left,
.app-coremedia-content-page blockquote.float--right {
  margin: 0 0 32px;
  float: none;
}
.app-coremedia-content-page .back-link {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: block;
}
.app-coremedia-content-page .back-link svg {
  width: 16px;
  height: 16px;
}
@media only screen and (min-width: 1024px) {
  .app-coremedia-content-page blockquote.float--left,
  .app-coremedia-content-page blockquote.float--right {
    width: 30%;
  }
  .app-coremedia-content-page blockquote.float--right {
    float: right;
    margin: 0 0 16px 16px;
  }
  .app-coremedia-content-page blockquote.float--left {
    float: left;
    margin: 0 16px 16px 0;
  }
}

.app-ethics-and-disclosures-images {
  display: flex;
}

.app-ethics-and-disclosures-images img {
  border: 1px solid #c5e0f4;
  border-radius: 10px;
  padding: 24px;
  object-fit: contain;
  height: 100px;
  width: auto;
}

@media only screen and (min-width: 480px) {
  .app-ethics-and-disclosures-images {
    display: block;
  }
  .app-ethics-and-disclosures-images img {
    width: 180px;
    height: 134px;
  }
}
.app-institution-signpost {
  background-color: #eff6fb;
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  margin: 0 -16px;
  min-height: 114px;
  position: relative;
  padding: 24px 16px;
}

.app-content-page .app-institution-signpost__name {
  font-size: 1.375rem;
  margin: 0 0 8px;
}

.app-institution-signpost__adjust-for-icon {
  width: calc(100% - 48px);
}

.app-institution-signpost__icon-container {
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 16px;
  height: 48px;
  width: 48px;
}

.app-institution-signpost__icon {
  display: inline-block;
  fill: #0088cc;
  height: 24px;
  width: 24px;
}

.app-institution-signpost__separator {
  border-top: 1px solid #c5e0f4;
  margin-top: 24px;
  padding-top: 24px;
}

.app-institution-signpost__funding-msg {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 0 8px;
}
.app-institution-signpost__funding-msg p {
  margin: 0 0 8px;
}

.app-content-page a.app-institution-signpost__link {
  color: #555555;
  font-size: 0.875rem;
  margin: 0;
}
.app-content-page a.app-institution-signpost__link:visited, .app-content-page a.app-institution-signpost__link:hover {
  color: #555555;
}

.app-content-page a.app-institution-signpost__funding-link {
  color: #222222;
  font-weight: 400;
  margin: 0;
}
.app-content-page a.app-institution-signpost__funding-link:visited, .app-content-page a.app-institution-signpost__funding-link:hover {
  color: #222222;
}

.app-institution-signpost__inline-icon {
  fill: currentColor;
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
}

.app-institution-signpost__inline-icon--edit {
  margin-left: 0;
  margin-right: 4px;
}

@media only screen and (min-width: 480px) {
  .app-institution-signpost {
    border-radius: 12px;
    margin: 0;
    padding: 24px;
  }
  .app-institution-signpost__icon-container {
    right: 24px;
    top: 24px;
    height: 64px;
    width: 64px;
  }
  .app-institution-signpost__icon {
    height: 32px;
    width: 32px;
  }
  .app-institution-signpost__adjust-for-icon {
    width: calc(100% - 64px);
  }
}
.app-info-presentation-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 16px;
  line-height: 1.4;
}

.app-info-presentation-box__item {
  width: 100%;
  border: 1px solid #c5e0f4;
  border-radius: 10px;
}
.app-info-presentation-box__item + .app-info-presentation-box__item {
  margin-top: 16px;
}

.app-info-presentation-box__item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px 16px 24px;
}

.app-info-presentation-box__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f0f7fc;
  padding: 14px;
}

.app-info-presentation-box__icon {
  fill: #0088cc;
  width: 2rem;
  height: 2rem;
}

.app-info-presentation-box__title {
  font-size: 1.125rem;
  margin: 10px 0 0;
}

.app-info-presentation-box__item p {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  margin: 4px 0 0;
}

@media only screen and (min-width: 768px) {
  .app-info-presentation-box {
    flex-direction: row;
    margin: 0 0 32px;
  }
  .app-info-presentation-box__item {
    width: calc(34% - 16px);
  }
  .app-info-presentation-box__item + .app-info-presentation-box__item {
    margin-top: 0;
  }
}
.app-jflow-content-page .float--left {
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
}
.app-jflow-content-page .float--right {
  float: right;
  margin-left: 16px;
  margin-bottom: 16px;
}
.app-jflow-content-page img {
  border-radius: 10px;
}
.app-jflow-content-page p img.float--right,
.app-jflow-content-page p img.float--left,
.app-jflow-content-page p img.float--none,
.app-jflow-content-page p img {
  height: auto;
  margin-right: 16px;
}
@media only screen and (min-width: 480px) {
  .app-jflow-content-page p img {
    height: auto;
  }
  .app-jflow-content-page p img.float--right,
  .app-jflow-content-page p img.float--left {
    width: auto;
    max-width: 50%;
  }
  .app-jflow-content-page p:has(> img)::after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: left;
  }
}

.app-journal-editorial__editor-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0 0 32px 0;
}

.app-journal-editorial-list__item {
  padding: 0;
  font-size: 1.125rem;
  margin: 8px 0 16px 0;
  font-family: "Merriweather", serif;
  line-height: 32px;
}

.app-journal-filter-by {
  background-color: #eff6fb;
  border-radius: 12px;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 16px;
}

.app-journal-filter-by-sort-by {
  background-color: #eff6fb;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 16px 16px 24px;
  margin: 0 -16px;
}
.app-journal-filter-by-sort-by legend {
  padding: 0;
}
.app-journal-filter-by-sort-by fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.app-journal-filter-by-sort-by fieldset {
  margin-top: 24px;
}

.app-journal-filter-by-sort-by__filter-by-container {
  border-bottom: 1px solid #cedbe0;
  padding-bottom: 24px;
  margin-bottom: 16px;
}

.app-journal-filter-by-sort-by legend {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 8px;
}

.app-journal-filter-by-sort-by label {
  font-size: 0.875rem;
}

.app-journal-filter-by-sort-by__fieldset-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
}

.app-journal-filter-by-sort-by input[type=radio] {
  margin-right: 8px;
}

.app-journal-filter-by-sort-by__radios {
  display: flex;
}
.app-journal-filter-by-sort-by__radios label:first-of-type {
  margin-right: 32px;
}

.app-journal-filter-by-sort-by__select-container {
  position: relative;
}

.app-journal-filter-by-sort-by__select-container select {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #000;
  color: #000;
  width: 100%;
  padding: 0.8rem 0.5rem;
  background-color: #fff;
  font-weight: 400;
  text-align: left;
  border-radius: 3px;
  font-size: 0.875rem;
}
.app-journal-filter-by-sort-by__select-container select:hover, .app-journal-filter-by-sort-by__select-container select:focus {
  color: #000;
  background-color: #fff;
}
.app-journal-filter-by-sort-by__select-container select option {
  color: #025e8d;
  background-color: #fff;
  background-image: none;
  border: 2px solid #025e8d;
  box-shadow: #fff;
}

.app-journal-filter-by-sort-by__select-container svg {
  position: absolute;
  right: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.app-journal-filter-by-sort-by .eds-c-button {
  margin-top: 24px;
}

.app-journal-filter-by-sort-by__publishing-model {
  margin-top: 16px;
}

.app-journal-filter-by-sort-by__fieldset-inner {
  margin-top: 16px;
}

@media only screen and (min-width: 532px) {
  .app-journal-filter-by-sort-by {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    margin: 0;
    padding: 24px;
  }
  .app-journal-filter-by-sort-by__flex-direction-column {
    flex-direction: column;
  }
  .app-journal-filter-by-sort-by__select-container {
    width: 190px;
  }
  .app-journal-filter-by-sort-by select {
    width: 190px;
  }
  .app-journal-filter-by-sort-by button {
    margin-top: 0;
    align-self: flex-end;
  }
  .app-journal-filter-by-sort-by fieldset {
    margin: 0;
    height: 100%;
  }
  .app-journal-filter-by-sort-by__form-controls {
    line-height: 45px;
  }
  .app-journal-filter-by-sort-by__fieldset-label {
    line-height: 1.4;
  }
  .app-journal-filter-by-sort-by__radios label:first-of-type {
    margin-right: 24px;
  }
  .app-journal-filter-by-sort-by legend {
    margin-bottom: 16px;
  }
  .app-journal-filter-by-sort-by__separator {
    height: auto;
    border-right: 1px solid #cedbe0;
    margin-right: 8px;
    padding-left: 8px;
  }
  .app-journal-filter-by-sort-by__filter-by-container,
  .app-journal-filter-by-sort-by__sort-by-container {
    flex-shrink: 0;
  }
  .app-journal-filter-by-sort-by__fieldset-inner {
    margin-top: 0;
  }
}
@media only screen and (min-width: 532px) and (max-width: 732px) {
  .app-journal-filter-by-sort-by__sort-by-container + .app-journal-filter-by-sort-by__separator {
    display: none;
  }
  .app-journal-filter-by-sort-by {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .app-journal-filter-by-sort-by .eds-c-button {
    margin-top: 16px;
  }
  .app-journal-filter-by-sort-by__separator {
    margin-right: 16px;
    padding-left: 16px;
  }
  .app-journal-filter-by-sort-by__publishing-model {
    margin-top: 16px;
  }
}
@media only screen and (min-width: 1024px) {
  .app-journal-filter-by-sort-by__fieldset-inner {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .app-journal-filter-by-sort-by__form-controls {
    display: flex;
    justify-content: space-between;
  }
  .app-journal-filter-by-sort-by__filter-by-controls-container {
    display: flex;
    flex-direction: row;
  }
  .app-journal-filter-by-sort-by__filter-by-controls-container .app-journal-filter-by-sort-by__form-controls {
    margin-right: 2rem;
    margin-top: 0;
  }
  .app-journal-filter-by-sort-by__fieldset-label {
    margin-right: 8px;
    align-self: center;
  }
  .app-journal-filter-by-sort-by__radios label:first-of-type {
    margin-right: 32px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1245px) {
  .app-journal-filter-by-sort-by__sort-by-container + .app-journal-filter-by-sort-by__separator {
    display: none;
  }
  .app-journal-filter-by-sort-by {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .app-journal-filter-by-sort-by .eds-c-button {
    margin-top: 16px;
  }
}
@media only screen and (min-width: 1100px) {
  .app-journal-filter-by-sort-by {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .app-journal-filter-by-sort-by__sort-by-container + .app-journal-filter-by-sort-by__separator {
    display: none;
  }
  .app-journal-filter-by-sort-by__radios {
    margin-right: 16px;
  }
  .app-journal-filter-by-sort-by button {
    margin-left: auto;
  }
  .app-journal-filter-by-sort-by__form-controls.app-journal-filter-by-sort-by__publishing-model {
    margin-right: 0;
  }
  .app-journal-filter-by-sort-by__separator {
    margin-right: 16px;
    padding-left: 16px;
  }
}
@media only screen and (min-width: 532px) and (max-width: 1099px) {
  .app-journal-filter-by-sort-by__radios {
    display: flex;
  }
  .app-journal-filter-by-sort-by {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    margin: 0;
    padding: 24px;
  }
  .app-journal-filter-by-sort-by__flex-direction-column {
    flex-direction: column;
  }
  .app-journal-filter-by-sort-by__select-container {
    width: 190px;
  }
  .app-journal-filter-by-sort-by select {
    width: 190px;
  }
  .app-journal-filter-by-sort-by button {
    align-self: flex-end;
  }
  .app-journal-filter-by-sort-by fieldset {
    margin: 0;
    height: 100%;
  }
  .app-journal-filter-by-sort-by__form-controls {
    line-height: 45px;
  }
  .app-journal-filter-by-sort-by__fieldset-label {
    line-height: 1.4;
  }
  .app-journal-filter-by-sort-by__radios label:first-of-type {
    margin-right: 24px;
  }
  .app-journal-filter-by-sort-by legend {
    margin-bottom: 16px;
  }
  .app-journal-filter-by-sort-by__separator {
    height: auto;
    border-right: 1px solid #cedbe0;
    margin-right: 8px;
    padding-left: 8px;
  }
  .app-journal-filter-by-sort-by__filter-by-container,
  .app-journal-filter-by-sort-by__sort-by-container {
    flex-shrink: 0;
  }
  .app-journal-filter-by-sort-by__fieldset-inner {
    margin-top: 0;
  }
  .app-journal-filter-by-sort-by__sort-by-container + .app-journal-filter-by-sort-by__separator {
    display: none;
  }
}
@media only screen and (min-width: 1099px) and (max-width: 1244px) {
  .app-journal-filter-by-sort-by .app-journal-filter-by-sort-by__separator {
    border-color: transparent;
  }
  .app-journal-filter-by-sort-by__fieldset-inner {
    margin-bottom: 15px;
  }
  .app-journal-filter-by-sort-by.app-journal-filter-by-sort-by__flex-direction-column:has(.app-journal-filter-by-sort-by__form-controls.app-journal-filter-by-sort-by__publishing-model) .app-journal-filter-by-sort-by__fieldset-inner {
    flex-direction: column;
  }
  .app-journal-filter-by-sort-by.app-journal-filter-by-sort-by__flex-direction-column:has(.app-journal-filter-by-sort-by__form-controls.app-journal-filter-by-sort-by__publishing-model) .app-journal-filter-by-sort-by__form-controls {
    display: block;
  }
}
.feature-flag-journal_page_improvements_part_2 .app-journal-latest-issue {
  background-color: #eff6fb;
}

.feature-flag-journal_page_improvements_part_2 .app-journal-latest-issue__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.app-journal-latest-issue__inner {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  border-top: 1px solid #222222;
  border-bottom: 1px solid #222222;
  padding: 16px 0;
  overflow: hidden;
  line-height: 1.4;
}
.feature-flag-journal_page_improvements_part_2 .app-journal-latest-issue__inner {
  border: none;
}
@media only screen and (min-width: 480px) {
  .feature-flag-journal_page_improvements_part_2 .app-journal-latest-issue__inner {
    padding: 0;
  }
}

.app-journal-latest-issue__heading {
  font-size: clamp(1.125rem, 4vw, 1.75rem);
  margin-bottom: 4px;
}

.app-journal-latest-issue--journalPage .app-journal-latest-issue__heading {
  font-size: clamp(1rem, 4vw, 1.5rem);
  margin-bottom: 8px;
}

.app-journal-latest-issue__date,
.app-journal-latest-issue__volume {
  font-size: clamp(0.875rem, 4vw, 1rem);
}

.app-journal-latest-issue__volume:before {
  content: "|";
  display: inline-block;
  color: #c5e0f4;
  padding: 0 8px;
}

.app-journal-latest-issue__cover {
  grid-area: 1/1/3/2;
  width: 60px;
  margin: 0;
  margin-right: 8px;
}

.app-journal-latest-issue--journalPage .app-journal-latest-issue__cover {
  width: 88px;
  margin-right: 12px;
}

.app-journal-latest-issue__cover img {
  width: 100%;
  height: auto;
}

.app-journal-latest-issue__contents {
  grid-area: 1/2/2/3;
}

.app-journal-latest-issue__title {
  font-size: clamp(1rem, 4vw, 1.25rem);
  margin: 8px 0 16px;
}

.app-journal-latest-issue--journalPage .app-journal-latest-issue__title {
  grid-area: 1/2/2/4;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0;
}

.app-journal-latest-issue__supplement {
  font-size: 0.875rem;
  font-weight: 700;
  margin-top: 16px;
}

.app-journal-latest-issue__info {
  grid-area: 3/1/4/3;
  font-size: 0.875rem;
  line-height: 24px;
}

.app-journal-latest-issue--journalPage .app-journal-latest-issue__info {
  grid-area: 2/1/4/3;
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: auto auto;
  grid-row-gap: 24px;
}

.app-journal-latest-issue__note + .app-journal-latest-issue__details {
  margin-top: 16px;
}

.app-show-more-show-less + .app-journal-latest-issue__details {
  margin-top: 24px;
}

.app-journal-latest-issue__details {
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 0;
  margin: 0;
}

.app-journal-latest-issue__details-item {
  display: inline-block;
  position: relative;
  margin-right: 10px;
}
.app-journal-latest-issue__details-item a {
  color: currentColor;
}
.app-journal-latest-issue__details-item:last-of-type {
  margin-right: 0;
}
.app-journal-latest-issue__details-item:first-child:nth-last-child(2) ~ .app-journal-latest-issue__details-item, .app-journal-latest-issue__details-item:first-child:nth-last-child(3) ~ .app-journal-latest-issue__details-item {
  line-height: 1;
}
.app-journal-latest-issue__details-item:first-child:nth-last-child(2) ~ .app-journal-latest-issue__details-item {
  border-left: 2px solid #c5e0f4;
  border-right: none;
  padding-left: 13px;
}
.app-journal-latest-issue__details-item:nth-of-type(2):has(+ .app-journal-latest-issue__details-item) {
  border-left: 2px solid #c5e0f4;
  border-right: 2px solid #c5e0f4;
  padding: 0 13px;
}

.app-journal-latest-issue__note {
  font-size: 0.875rem;
  line-height: 1.8;
}

.app-journal-latest-issue__note.show-more-show-less {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.app-journal-latest-issue .app-show-more-show-less {
  margin-top: 8px;
}
.app-journal-latest-issue .app-show-more-show-less__icon {
  width: 1rem;
  height: 1rem;
  margin-right: 6px;
}

.app-journal-latest-issue__note.show-more-show-less ~ .app-show-more-show-less span {
  font-size: 0.875rem;
}

.app-journal-latest-issue__details + .app-journal-latest-issue__editors {
  margin-top: 8px;
}

.app-journal-latest-issue__editors {
  font-weight: 700;
  font-size: 0.875rem;
}

.app-journal-latest-issue__editors-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline;
}

.app-journal-latest-issue__editors dt,
.app-journal-latest-issue__editors dd {
  display: inline;
}

.app-journal-latest-issue__editors dt {
  margin-right: 4px;
}

.app-journal-latest-issue__editor {
  font-weight: 400;
  display: inline;
}

.app-journal-latest-issue__editors:last-child {
  margin-bottom: 0;
}

.app-journal-latest-issue__svg {
  fill: #0070a8;
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-right: 8px;
}

.app-journal-latest-issue__link {
  margin-top: 16px;
  text-align: center;
}
.app-journal-latest-issue__link a {
  width: 100%;
  line-height: 1.8;
}
@media only screen and (min-width: 480px) {
  .app-journal-latest-issue__link {
    margin: 0;
  }
}

.app-journal-latest-issue--journalPage .app-journal-latest-issue__link {
  grid-area: 3/1/3/4;
}

*:not(.app-journal-latest-issue__editors) ~ .app-journal-latest-issue__supplement-file {
  margin-top: 16px;
}

.app-journal-latest-issue__supplement-file {
  display: block;
  color: currentColor;
}

@media only screen and (min-width: 768px) {
  .app-journal-latest-issue {
    padding: 32px 0;
  }
  .app-journal-latest-issue__cover,
  .app-journal-latest-issue--journalPage .app-journal-latest-issue__cover {
    width: 115px;
    margin-bottom: 0;
    margin-right: 16px;
  }
  .app-journal-latest-issue__note.show-more-show-less.app-show-more-show-less--expanded {
    display: initial;
  }
  .u-list-reset.app-journal-latest-issue__details {
    margin-top: 16px;
  }
  .app-journal-latest-issue__info {
    grid-area: 2/2/4/3;
    align-self: center;
    margin-left: 0;
  }
  .app-journal-latest-issue--journalPage .app-journal-latest-issue__info {
    grid-area: 2/1/3/3;
    grid-template-columns: 131px auto;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  .app-journal-latest-issue--journalPage .app-journal-latest-issue__title,
  .app-journal-latest-issue--journalPage .app-journal-latest-issue__link {
    width: calc(100% - 130px);
    align-self: flex-end;
  }
  .app-journal-latest-issue__link {
    text-align: left;
  }
  .app-journal-latest-issue__link a {
    width: 335px;
  }
}
.app-journal-masthead {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  padding: 16px 0 24px;
  font-family: "Merriweather Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  /* stylelint-disable declaration-colon-space-after */
  /* stylelint-disable indentation */
  grid-template-areas: "breadcrumbs breadcrumbs" "journal-cover imprints-and-societies" "info info";
  /* stylelint-enable declaration-colon-space-after */
  /* stylelint-enable indentation */
}
.app-journal-masthead--imprint-with-society {
  /* stylelint-disable declaration-colon-space-after */
  /* stylelint-disable indentation */
  grid-template-areas: "breadcrumbs breadcrumbs" "journal-cover journal-cover" "info info" "imprints-and-societies imprints-and-societies";
  /* stylelint-enable declaration-colon-space-after */
  /* stylelint-enable indentation */
}
.app-journal-masthead--journal-child-pages {
  grid-template-columns: auto 1fr;
  /* stylelint-disable declaration-colon-space-after */
  /* stylelint-disable indentation */
  grid-template-areas: "breadcrumbs breadcrumbs" "journal-cover info";
  /* stylelint-enable declaration-colon-space-after */
  /* stylelint-enable indentation */
}

.app-journal-masthead__breadcrumbs {
  grid-area: breadcrumbs;
}

.app-journal-masthead__imprints-and-societies {
  grid-area: imprints-and-societies;
  justify-self: flex-end;
  padding-top: 16px;
}
.app-journal-masthead--imprint-with-society .app-journal-masthead__imprints-and-societies {
  justify-self: initial;
  padding-top: 0;
}
.app-journal-masthead--journal-child-pages .app-journal-masthead__imprints-and-societies {
  display: none;
}

.app-journal-masthead__info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 8px;
  font-size: 1rem;
}
.app-journal-masthead--journal-child-pages .app-journal-masthead__info {
  padding: 16px 0 0 8px;
  line-height: 1.2;
}

.app-journal-masthead__title {
  margin: 0;
  line-height: 1.2;
  font-weight: 700;
  word-break: break-word;
}
.app-journal-masthead--journal-child-pages .app-journal-masthead__title {
  font-size: 1.25rem;
}

.app-journal-masthead__subtitle {
  line-height: 1.2;
  max-width: 100%;
}
.app-journal-masthead--journal-child-pages .app-journal-masthead__subtitle {
  display: none;
}

.app-journal-masthead__publishing-model {
  font-size: 0.875rem;
  padding: 16px 0;
}
.app-journal-masthead__publishing-model dl {
  margin: 0;
}
.app-journal-masthead__publishing-model dd {
  font-weight: 700;
}
.app-journal-masthead--journal-child-pages .app-journal-masthead__publishing-model {
  padding: 8px 0 0;
}
.app-journal-masthead--journal-child-pages .app-journal-masthead__publishing-model dl {
  display: flex;
}

.app-journal-masthead__publishing-model-colon {
  display: none;
}

.app-journal-masthead--journal-child-pages .app-journal-masthead__publishing-model-colon {
  display: inline-block;
  margin-right: 4px;
}

.app-journal-masthead__title-link, .app-journal-masthead__title-link:hover, .app-journal-masthead__title-link:focus, .app-journal-masthead__title-link:active, .app-journal-masthead__title-link:visited,
.app-journal-masthead__publishing-model-link,
.app-journal-masthead__publishing-model-link:hover,
.app-journal-masthead__publishing-model-link:focus,
.app-journal-masthead__publishing-model-link:active,
.app-journal-masthead__publishing-model-link:visited {
  color: currentColor;
}

.app-journal-masthead__journal-cover {
  grid-area: journal-cover;
  padding-top: 16px;
}

.app-journal-masthead__journal-cover img {
  border: 2px solid #ffffff;
  border-radius: 7px;
  width: 100%;
  height: auto;
  max-width: 90px;
}
.app-journal-masthead--journal-child-pages .app-journal-masthead__journal-cover img {
  max-width: 64px;
}

.app-journal-masthead__button .eds-c-button {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  color: #01324b;
  width: 100%;
}
.app-journal-masthead--journal-child-pages .app-journal-masthead__button .eds-c-button {
  display: none;
}
.app-journal-masthead__button .eds-c-button svg {
  fill: #01324b;
}

.app-journal-masthead__imprint-society-container {
  border: 2px solid #ffffff;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}
.app-journal-masthead__imprint-society-container .app-journal-masthead__imprint, .app-journal-masthead__imprint-society-container .app-journal-masthead__society {
  flex-basis: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
.app-journal-masthead__imprint-society-container .app-journal-masthead__society span:nth-child(2) {
  margin-left: 48px;
}
.app-journal-masthead__imprint-society-container .app-journal-masthead__imprint img, .app-journal-masthead__imprint-society-container .app-journal-masthead__society img {
  width: auto;
  height: auto;
}
.app-journal-masthead__imprint-society-container .app-journal-masthead__imprint img {
  max-width: 168px;
  max-height: 24px;
}
.app-journal-masthead__imprint-society-container .app-journal-masthead__society img {
  max-width: 80px;
  max-height: 36px;
}
.app-journal-masthead__imprint-society-container .app-journal-masthead__society {
  background-color: #ffffff;
}

@media only screen and (min-width: 480px) {
  .app-journal-masthead {
    grid-template-columns: auto minmax(0, 1fr) auto;
    /* stylelint-disable declaration-colon-space-after */
    /* stylelint-disable indentation */
    grid-template-areas: "breadcrumbs breadcrumbs imprints-and-societies" "journal-cover info imprints-and-societies";
    /* stylelint-enable declaration-colon-space-after */
    /* stylelint-enable indentation */
    padding: 24px 0 32px;
  }
  .app-journal-masthead--imprint-with-society {
    grid-template-columns: auto minmax(0, 1fr);
    /* stylelint-disable declaration-colon-space-after */
    /* stylelint-disable indentation */
    grid-template-areas: "breadcrumbs breadcrumbs" "journal-cover info" "imprints-and-societies imprints-and-societies";
    /* stylelint-enable declaration-colon-space-after */
    /* stylelint-enable indentation */
  }
  .app-journal-masthead__journal-cover img, .app-journal-masthead--journal-child-pages .app-journal-masthead__journal-cover img {
    max-width: 160px;
  }
  .app-journal-masthead__imprints-and-societies {
    padding-top: 0;
  }
  .app-journal-masthead--journal-child-pages .app-journal-masthead__imprints-and-societies {
    display: inline-block;
  }
  .app-journal-masthead__info, .app-journal-masthead--journal-child-pages .app-journal-masthead__info {
    padding-left: 24px;
    padding-top: 24px;
    line-height: 1.8;
  }
  .app-journal-masthead__button {
    align-self: flex-start;
  }
  .app-journal-masthead__title, .app-journal-masthead--journal-child-pages .app-journal-masthead__title {
    line-height: 1.2;
    font-size: 2.25rem;
  }
  .app-journal-masthead__subtitle {
    font-size: 1.25rem;
    margin-right: -64px;
  }
  .app-journal-masthead--journal-child-pages .app-journal-masthead__subtitle {
    display: block;
  }
  .app-journal-masthead__publishing-model, .app-journal-masthead--journal-child-pages .app-journal-masthead__publishing-model {
    padding: 24px 0 16px;
  }
  .app-journal-masthead__publishing-model dd {
    font-size: 1rem;
  }
  .app-journal-masthead--journal-child-pages .app-journal-masthead__publishing-model dl {
    display: block;
  }
  .app-journal-masthead__publishing-model-colon, .app-journal-masthead--journal-child-pages .app-journal-masthead__publishing-model-colon {
    display: none;
  }
  .app-journal-masthead__journal-cover {
    padding-top: 24px;
  }
  .app-journal-masthead__button .eds-c-button {
    margin-right: -64px;
    width: auto;
  }
  .app-journal-masthead--journal-child-pages .app-journal-masthead__button .eds-c-button {
    display: inline-block;
  }
}
@media only screen and (min-width: 1024px) {
  .app-journal-masthead--imprint-with-society {
    grid-template-columns: auto minmax(0, 1fr) minmax(0, 300px);
    /* stylelint-disable declaration-colon-space-after */
    /* stylelint-disable indentation */
    grid-template-areas: "breadcrumbs breadcrumbs breadcrumbs" "journal-cover info imprints-and-societies";
    /* stylelint-enable declaration-colon-space-after */
    /* stylelint-enable indentation */
  }
  .app-journal-masthead__subtitle {
    margin-right: 0;
  }
  .app-journal-masthead__button .eds-c-button {
    margin-right: 0;
  }
  .app-journal-masthead--imprint-with-society .app-journal-masthead__imprints-and-societies {
    padding-top: 24px;
  }
  .app-journal-masthead__imprint-society-container {
    margin-top: 0;
  }
  .app-journal-masthead__imprint-society-container .app-journal-masthead__imprint, .app-journal-masthead__imprint-society-container .app-journal-masthead__society {
    flex-basis: 100px;
  }
  .app-journal-masthead__imprint-society-container .app-journal-masthead__society span:nth-child(2) {
    margin-left: 32px;
  }
  .app-journal-masthead__imprint-society-container .app-journal-masthead__imprint img {
    max-width: 125px;
    max-height: 32px;
  }
  .app-journal-masthead__imprint-society-container .app-journal-masthead__society img {
    max-height: 50px;
    max-width: 105px;
  }
}
.app-masthead-funding-info {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 16px 0;
}
.app-masthead-funding-info a {
  color: #ffffff;
}

@media only screen and (min-width: 480px) {
  .app-masthead-funding-info {
    padding: 24px 0;
    gap: 0px;
  }
}
.app-masthead-funding-info__details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
}

@media only screen and (min-width: 480px) {
  .app-masthead-funding-info__details {
    flex-direction: row;
    gap: 0;
  }
}
.app-masthead-funding-info__institution-details {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.app-masthead-funding-info__links {
  font-weight: 700;
}

.app-masthead-funding-info__links:not(:last-child) {
  border-right: 1px solid #fff;
  padding-right: 8px;
  margin-right: 8px;
}
.app-masthead-funding-info__links:not(:last-child), .app-masthead-funding-info__links:not(:last-child):hover, .app-masthead-funding-info__links:not(:last-child):focus, .app-masthead-funding-info__links:not(:last-child):active, .app-masthead-funding-info__links:not(:last-child):visited {
  color: currentColor;
}

.app-masthead-funding-info__icon {
  fill: currentColor;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 8px;
}

.app-masthead-funding-info__institution {
  font-weight: 700;
  padding-right: 16px;
  padding-bottom: 8px;
  display: flex;
}

@media only screen and (min-width: 480px) {
  .app-masthead-funding-info__institution {
    padding-bottom: 0px;
  }
}
.app-masthead-funding-info__message {
  margin-right: 16px;
}

.app-masthead-funding__wrapper {
  background: rgba(255, 255, 255, 0.05);
  position: relative;
  z-index: 2;
}

.app-journal-homepage-metrics {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c5e0f4;
  font-size: 0.875rem;
}
.app-journal-homepage-metrics:first-of-type {
  border-top: 1px solid #c5e0f4;
}
.app-journal-homepage-metrics svg {
  flex-shrink: 0;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.app-journal-nav {
  margin: 8px 0 32px;
}

.app-journal-nav .eds-c-button {
  padding: 8px 24px;
}

.app-journal-nav__item--hide-mobile {
  display: none;
}

.app-journal-nav__item--hide-desktop {
  display: inline-block;
}

@media only screen and (min-width: 480px) {
  .app-journal-nav {
    margin: 8px 8px 48px 0;
  }
  .app-journal-nav__item--hide-mobile {
    display: inline-block;
  }
  .app-journal-nav__item--hide-desktop {
    display: none;
  }
}
.app-journal-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  gap: 16px;
}

.app-journal-nav__item {
  flex: 1 0 100%;
}

@media only screen and (min-width: 480px) {
  .app-journal-nav__item {
    flex: 0 0 auto;
  }
}
.app-journal-nav-sidebar {
  padding: 0 16px;
  border: 1px solid #c5e0f4;
  border-radius: 8px;
  font-size: 0.875rem;
}

.app-journal-nav-sidebar__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-journal-nav-sidebar__item {
  padding: 16px 0;
  border-bottom: 1px solid #c5e0f4;
}

.app-journal-nav-sidebar__item:last-child {
  border-bottom: none;
}

.app-journal-nav-sidebar__icon {
  display: inline-block;
  width: 18px;
  height: 18px;
}

.app-journal-nav-sidebar__side {
  float: right;
}

.app-journal-nav-sidebar__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  background-color: #eff6fb;
  border-radius: 50%;
}

.app-journal-nav-sidebar__link {
  display: block;
  color: #222222;
}

.app-journal-nav-sidebar__link-text {
  line-height: 3em;
}

.app-journal-nav-sidebar__link:visited {
  color: #222222;
}

.app-journal-navigation {
  margin-bottom: 2rem;
}
.feature-flag-journal_page_improvements_part_2 .app-journal-navigation {
  margin: 0;
}

.app-journal-navigation-expander {
  background-color: #ffffff;
  border-top: 2px solid #c5e0f4;
  border-bottom: 2px solid #c5e0f4;
  padding: 1rem 0;
}
.app-journal-navigation-expander h2 {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  margin-bottom: 1.5rem;
}
.js .app-journal-navigation-expander {
  display: none;
}

.app-journal-navigation__mobile-trigger {
  background-color: transparent;
  border: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  color: #555555;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  border-top: 1px solid #c5e0f4;
  border-bottom: 1px solid #c5e0f4;
}
.app-journal-navigation__mobile-trigger svg {
  width: 24px;
  height: 24px;
  fill: #555555;
  color: #555555;
}
.app-journal-navigation__mobile-trigger.is-open {
  color: #025e8d;
  border-bottom: none;
}
.app-journal-navigation__mobile-trigger.is-open svg {
  transform: rotate(180deg);
  fill: #025e8d;
  color: #025e8d;
}

.app-navigation__no-dropdown--active {
  display: flex;
}

.app-navigation__no-dropdown--active .app-accordion__strip {
  flex-grow: 0;
  width: 8px;
  background-color: #025e8d;
  margin-right: -8px;
}

.app-navigation__no-dropdown--active a {
  color: #025e8d;
}

@media only screen and (min-width: 768px) {
  .app-journal-navigation {
    margin-bottom: 3rem;
  }
  .feature-flag-journal_page_improvements_part_2 .app-journal-navigation {
    margin-bottom: 0;
  }
  .app-journal-navigation .app-navigation__desktop-menu {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 16px;
    padding: 0.5rem 1rem;
  }
  .app-journal-navigation__mobile-trigger {
    display: none;
  }
}
.app-journal-overview {
  padding: 24px 16px;
  background-color: #eff6fb;
  margin: 0 -16px;
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
}
.feature-flag-journal_page_improvements_part_2 .app-journal-overview {
  margin: 0;
}

@media only screen and (min-width: 480px) {
  .app-journal-overview {
    padding: 24px;
    border-radius: 12px;
    margin: 0;
  }
  .feature-flag-journal_page_improvements_part_2 .app-journal-overview {
    padding: 48px 0 64px 0;
  }
}
.app-journal-overview__inner {
  padding: 0;
}
@media only screen and (min-width: 480px) {
  .app-journal-overview__inner {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 16px;
  }
}

.app-journal-overview .l-with-sidebar {
  --with-sidebar--gap: 24px;
}
@media only screen and (min-width: 480px) {
  .app-journal-overview .l-with-sidebar {
    --with-sidebar--gap: 69px;
  }
}
@media only screen and (min-width: 1024px) {
  .app-journal-overview .l-with-sidebar {
    --with-sidebar--gap: 122px;
  }
}

.app-journal-overview__metrics {
  padding: 16px 0 0;
  margin: 16px 0 0;
  border-top: 1px solid #c5e0f4;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.app-journal-overview__metrics dl {
  margin: 0;
}

@media only screen and (min-width: 480px) {
  .app-journal-overview li::before {
    margin-right: 12px;
  }
  .app-journal-overview__metrics {
    display: flex;
    flex-wrap: wrap;
  }
}
.app-journal-overview__promo-text li p {
  margin: 0;
  display: inline;
}

.feature-flag-journal_page_improvements_part_2 .app-journal-overview__editors-container {
  padding: 24px 0;
  margin: 24px 0;
  border-bottom: 1px solid #c5e0f4;
  border-top: 1px solid #c5e0f4;
}

.app-journal-overview__metric {
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  margin-bottom: 16px;
  line-height: 1.4;
}

.app-journal-overview__metric:last-of-type {
  margin-bottom: 0;
}

@media only screen and (min-width: 480px) {
  .app-journal-overview__metric {
    flex-basis: 50%;
    margin: 0;
  }
  .app-journal-overview__metric:nth-of-type(n + 3) {
    margin-top: 16px;
  }
}
.app-journal-overview__metric-label,
.app-journal-overview__metric-value {
  margin: 0;
}

.app-journal-overview__metric-value {
  font-weight: 700;
}

.app-journal-overview__metric-label {
  font-weight: 400;
  font-size: 0.875rem;
}

.app-journal-overview__metric-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid #999999;
  margin-right: 16px;
}

.app-journal-overview__metric-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.app-journal-overview__metric circle {
  fill: #222222;
}

.app-journal-overview__editor > * {
  margin: 0;
}

.app-journal-overview__editor {
  padding: 16px 0 0;
  margin: 16px 0 0;
  border-top: 1px solid #c5e0f4;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.app-journal-overview__editor dt {
  font-weight: 700;
}

.app-journal-overview-new-data-system__editor {
  column-count: 1;
}

@media only screen and (min-width: 480px) {
  .app-journal-overview__editor-list:has(.app-journal-overview__editor-list-item:nth-child(n+3)) {
    column-count: 2;
  }
}
.app-journal-overview__editor-list-item {
  break-inside: avoid;
}

.app-journal-overview__editor-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-journal-overview__view-all-editors {
  margin: 16px 0 0;
}
.app-journal-overview__view-all-editors svg {
  display: inline-block;
  vertical-align: middle;
  fill: #025e8d;
}

.app-language-checker-sidebar-card .app-card-highlight--stack img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}

@media only screen and (min-width: 480px) {
  .app-language-checker-sidebar-card .app-card-highlight--stack img {
    width: 200px;
    height: 100px;
    aspect-ratio: 2/1;
  }
}
@media only screen and (min-width: 1024px) {
  .app-language-checker-sidebar-card .app-card-highlight--stack img {
    width: 300px;
    height: 200px;
    aspect-ratio: 3/2;
  }
}
.app-latest-articles-ctas {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 16px;
}

.app-latest-articles-ctas__button {
  flex-basis: 100%;
}

@media only screen and (min-width: 480px) {
  .app-latest-articles-ctas {
    justify-content: initial;
  }
  .app-latest-articles-ctas__button {
    flex-basis: auto;
  }
}
.app-latest-articles-ctas__message {
  font-size: 1rem;
}

.app-latest-articles-ctas__link {
  display: inline-flex;
  align-items: center;
}

.app-latest-articles-ctas__icon {
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 4px;
  fill: currentColor;
}

.app-societies.app-societies--sidebar {
  flex-grow: 0;
  flex-basis: auto;
  margin: 0 auto 32px;
}

@media only screen and (min-width: 480px) {
  .app-societies.app-societies--sidebar {
    margin: 0 0 32px;
  }
}
.app-societies__heading {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

.app-societies__description {
  font-size: 0.875rem;
}

.app-societies__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px 0;
  margin-top: 24px;
}
@media only screen and (min-width: 768px) {
  .app-societies__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 16px;
  }
}

.app-societies__list-item {
  display: grid;
  align-items: stretch;
}

.app-societies__list-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: #222222;
  padding: 24px;
  border: 1px solid #c5e0f4;
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1.5;
}
@media only screen and (min-width: 768px) {
  .app-societies__list-content {
    flex-basis: calc(50% - 8px);
    flex-direction: row;
  }
}
.app-societies__list-content:hover, .app-societies__list-content:visited {
  color: #222222;
}

.app-societies__list-image {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
}
.app-societies__list-image img {
  max-width: 125px;
  max-height: 72px;
  width: auto;
  height: auto;
}

.app-societies__list-text {
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .app-societies__list-text {
    text-align: left;
  }
}

.app-societies__list-link-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  vertical-align: middle;
  fill: #222222;
}

.app-societies__single-image {
  margin: 0 auto;
  max-width: 125px;
}
.app-societies__single-image img {
  max-width: 125px;
  max-height: 72px;
  width: auto;
  height: auto;
}
@media only screen and (min-width: 480px) {
  .app-societies__single-image {
    margin: 0;
  }
}

.app-long-form-content-container {
  padding-right: calc(100% - 740px);
}

.app-masthead__colour-1 {
  --background-color: rgb(255, 21, 0);
}

.app-masthead__colour-2 {
  --background-color: rgb(255, 64, 0);
}

.app-masthead__colour-3 {
  --background-color: rgb(255, 106, 0);
}

.app-masthead__colour-4 {
  --background-color: rgb(255, 149, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-5 {
  --background-color: rgb(255, 191, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-6 {
  --background-color: rgb(255, 234, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-7 {
  --background-color: rgb(234, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-8 {
  --background-color: rgb(191, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-9 {
  --background-color: rgb(149, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-10 {
  --background-color: rgb(106, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-11 {
  --background-color: rgb(43, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-12 {
  --background-color: rgb(0, 255, 43);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-13 {
  --background-color: rgb(0, 255, 106);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-14 {
  --background-color: rgb(0, 255, 149);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-15 {
  --background-color: rgb(0, 255, 191);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-16 {
  --background-color: rgb(0, 255, 234);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-17 {
  --background-color: rgb(0, 234, 255);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-18 {
  --background-color: rgb(0, 191, 255);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-19 {
  --background-color: rgb(0, 149, 255);
}

.app-masthead__colour-20 {
  --background-color: rgb(0, 106, 255);
}

.app-masthead__colour-21 {
  --background-color: rgb(0, 21, 255);
}

.app-masthead__colour-22 {
  --background-color: rgb(121, 77, 255);
}

.app-masthead__colour-23 {
  --background-color: rgb(151, 77, 255);
}

.app-masthead__colour-24 {
  --background-color: rgb(170, 51, 255);
}

.app-masthead__colour-25 {
  --background-color: rgb(198, 26, 255);
}

.app-masthead__colour-26 {
  --background-color: rgb(255, 0, 234);
}

.app-masthead__colour-27 {
  --background-color: rgb(255, 0, 191);
}

.app-masthead__colour-28 {
  --background-color: rgb(255, 0, 149);
}

.app-masthead__colour-29 {
  --background-color: rgb(255, 0, 106);
}

.app-masthead__colour-30 {
  --background-color: rgb(255, 0, 64);
}

.app-masthead__colour-31 {
  --background-color: rgb(255, 0, 21);
}

.app-masthead--pastel {
  --gradient-light: rgba(255, 255, 255, 0.9);
  --gradient-dark: rgba(255, 255, 255, 0.75);
  --masthead-color: #000;
}

.app-masthead__colour-1--pastel {
  --background-color: rgb(255, 138, 128);
}

.app-masthead__colour-2--pastel {
  --background-color: rgb(255, 159, 128);
}

.app-masthead__colour-3--pastel {
  --background-color: rgb(255, 181, 128);
}

.app-masthead__colour-4--pastel {
  --background-color: rgb(255, 191, 102);
}

.app-masthead__colour-5--pastel {
  --background-color: rgb(255, 217, 102);
}

.app-masthead__colour-6--pastel {
  --background-color: rgb(255, 242, 102);
}

.app-masthead__colour-7--pastel {
  --background-color: rgb(242, 255, 102);
}

.app-masthead__colour-8--pastel {
  --background-color: rgb(223, 255, 128);
}

.app-masthead__colour-9--pastel {
  --background-color: rgb(202, 255, 128);
}

.app-masthead__colour-10--pastel {
  --background-color: rgb(181, 255, 128);
}

.app-masthead__colour-11--pastel {
  --background-color: rgb(170, 255, 153);
}

.app-masthead__colour-12--pastel {
  --background-color: rgb(153, 255, 170);
}

.app-masthead__colour-13--pastel {
  --background-color: rgb(153, 255, 195);
}

.app-masthead__colour-14--pastel {
  --background-color: rgb(153, 255, 204);
}

.app-masthead__colour-15--pastel {
  --background-color: rgb(153, 255, 229);
}

.app-masthead__colour-16--pastel {
  --background-color: rgb(153, 255, 246);
}

.app-masthead__colour-17--pastel {
  --background-color: rgb(153, 246, 255);
}

.app-masthead__colour-18--pastel {
  --background-color: rgb(153, 229, 255);
}

.app-masthead__colour-19--pastel {
  --background-color: rgb(153, 212, 255);
}

.app-masthead__colour-20--pastel {
  --background-color: rgb(153, 195, 255);
}

.app-masthead__colour-21--pastel {
  --background-color: rgb(178, 191, 255);
}

.app-masthead__colour-22--pastel {
  --background-color: rgb(178, 178, 255);
}

.app-masthead__colour-23--pastel {
  --background-color: rgb(198, 178, 255);
}

.app-masthead__colour-24--pastel {
  --background-color: rgb(210, 178, 255);
}

.app-masthead__colour-25--pastel {
  --background-color: rgb(223, 178, 255);
}

.app-masthead__colour-26--pastel {
  --background-color: rgb(236, 178, 255);
}

.app-masthead__colour-27--pastel {
  --background-color: rgb(255, 178, 249);
}

.app-masthead__colour-28--pastel {
  --background-color: rgb(255, 153, 229);
}

.app-masthead__colour-29--pastel {
  --background-color: rgb(255, 153, 212);
}

.app-masthead__colour-30--pastel {
  --background-color: rgb(255, 153, 195);
}

.app-masthead__colour-31--pastel {
  --background-color: rgb(255, 153, 161);
}

.app-masthead {
  position: relative;
  background: #0070a8;
  background: var(--background-color, #0070a8);
}

.app-masthead::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: radial-gradient(circle at top right, var(--gradient-light, rgba(0, 0, 0, 0.4)), var(--gradient-dark, rgba(0, 0, 0, 0.7)));
}

.app-masthead--pastel .app-masthead {
  background: rgb(102, 170, 204);
  background: var(--background-color, rgb(102, 170, 204));
}

.app-masthead.app-masthead--disable-journal-colour::after {
  background: unset;
}

@media only screen and (max-width: 479px) {
  .app-masthead::after {
    background: linear-gradient(225deg, var(--gradient-light, rgba(0, 0, 0, 0.4)), var(--gradient-dark, rgba(0, 0, 0, 0.7)));
  }
}
.app-masthead__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  position: relative;
  z-index: 1;
  color: var(--masthead-color, #fff);
}

.MathJax_SVG svg {
  width: auto;
  height: auto;
}

.MathJax_SVG svg {
  max-width: 100%;
}

#MathJax_Zoom svg {
  max-width: none;
}

.app-navigation__desktop-menu,
.app-navigation__dropdown {
  display: none;
}

.app-navigation__mobile-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-navigation__expander-enhanced-but-no-js .app-navigation__menu,
.app-navigation__expander-enhanced-but-no-js .app-navigation__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-navigation__expander-enhanced-but-no-js .app-navigation__menu {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 16px;
  grid-row-gap: 32px;
}
.app-navigation__expander-enhanced-but-no-js .app-navigation__dropdown {
  display: block;
}
.app-navigation__expander-enhanced-but-no-js .app-navigation__link-icon {
  display: none;
}

.app-navigation__no-dropdown a {
  display: block;
  width: 100%;
  text-decoration: none;
  color: #555555;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  border-top: 1px solid #c5e0f4;
  border-bottom: 1px solid #c5e0f4;
}

li + .app-navigation__no-dropdown a {
  border-top: none;
}

@media only screen and (min-width: 768px) {
  .app-navigation {
    border-bottom: 2px solid #c5e0f4;
  }
  .app-navigation__dropdown {
    display: block;
  }
  .app-navigation__mobile-menu {
    display: none;
  }
  .app-navigation__desktop-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    gap: 1rem;
    justify-content: flex-start;
    line-height: 1.2;
    padding: 0.5rem 1rem;
  }
  .app-navigation__menu-link {
    position: relative;
    display: flex;
    text-decoration: none;
    color: #222222;
    align-items: center;
    padding: 1rem 0.5rem;
  }
  .app-navigation__menu-link:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 6px;
    bottom: -8px;
    left: 0;
    background-color: transparent;
    border-radius: 8px;
  }
  .app-navigation__menu-link:hover, .app-navigation__menu-link.is-open {
    color: #025e8d;
  }
  .app-navigation__menu-link:hover:before, .app-navigation__menu-link.is-open:before {
    background-color: #0088cc;
  }
  .app-navigation__menu-link:hover .app-navigation__icon, .app-navigation__menu-link.is-open .app-navigation__icon {
    color: currentColor;
    fill: currentColor;
  }
  .app-navigation__menu-link.is-open .app-navigation__icon {
    transform: rotate(180deg);
  }
  .app-navigation__menu-link--active {
    color: initial;
  }
  .app-navigation__menu-link--active:before {
    background-color: #025e8d;
  }
  .app-navigation__icon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    transition: all 0.2s;
    color: #666666;
    fill: #666666;
  }
  .app-navigation--tray-dropdown .app-navigation__heading {
    font-size: 1rem;
    line-height: 1.8;
    margin: 0 0 8px;
  }
  .app-navigation--tray-dropdown .app-navigation__list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    color: #222222;
    font-weight: 400;
    line-height: 1.8;
  }
  .app-navigation--tray-dropdown .app-navigation__list-link {
    color: currentColor;
  }
  .app-navigation--tray-dropdown .app-navigation__dropdown {
    display: flex;
    row-gap: 2rem;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #c5e0f4;
    padding: 1rem 1.5rem;
    box-shadow: 0 4px 12px 0 rgba(1, 50, 75, 0.1);
  }
  .app-navigation--tray-dropdown .app-navigation__dropdown.has-tethered {
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    flex-direction: row;
    column-gap: 2rem;
    border-radius: 8px;
    border: 1px solid #c5e0f4;
  }
  .app-navigation--tray-dropdown .app-navigation__dropdown-group {
    padding: 16px;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__desktop-menu {
    border-bottom: none;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    color: #222222;
    font-weight: 400;
    line-height: 1.8;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__dropdown {
    padding: 9px 0;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__menu-item {
    position: relative;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__dropdown.has-tethered {
    position: absolute;
    width: 264px;
    z-index: 10;
    top: calc(100% + 8px);
    left: 0;
    flex-direction: row;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #c5e0f4;
    box-shadow: 0 4px 12px 0 rgba(1, 50, 75, 0.1);
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    color: currentColor;
    text-decoration: none;
    background-color: transparent;
    padding: 0 24px;
    transition: all 0.2s;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list-link--active {
    background-color: #f0f7fc;
    font-weight: 700;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list-link--active .app-navigation__link-icon {
    color: #555555;
    fill: #555555;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list-link:focus {
    background-color: #0070a8;
    color: #ffffff;
    outline: none;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list-link:focus .app-navigation__link-icon {
    color: currentColor;
    fill: currentColor;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list-link:hover {
    background-color: #0070a8;
    color: #ffffff;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list-link:hover .app-navigation__link-icon {
    color: currentColor;
    fill: currentColor;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list:hover .app-navigation__list-link:focus:not(:hover) {
    background-color: transparent;
    color: currentColor;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list:hover .app-navigation__list-link:focus:not(:hover) .app-navigation__link-icon {
    color: #666666;
    fill: #666666;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list:hover .app-navigation__list-link--active:focus:not(:hover) {
    background-color: #f0f7fc;
    font-weight: 700;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__list:hover .app-navigation__list-link--active:focus:not(:hover) .app-navigation__link-icon {
    color: #555555;
    fill: #555555;
  }
  .app-navigation--fixed-width-dropdown .app-navigation__link-icon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
    transition: all 0.2s;
    color: #666666;
    fill: #666666;
  }
}
.app-page-layout {
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.app-page-layout > * {
  margin: 0;
}

.app-page-layout__main,
.app-page-layout__side,
.app-page-layout__main > * {
  margin-bottom: 48px;
}

.app-page-layout__main--s {
  margin-top: 16px;
}

.app-page-layout__main--s > * {
  margin-bottom: 16px;
}

.app-page-layout__main--s .eds-c-card-composable:last-of-type {
  margin-bottom: 0;
}

.app-page-layout__side > * {
  margin-bottom: 24px;
}

.app-page-layout__main > *:last-child,
.app-page-layout__side > *:last-child {
  margin-bottom: 0;
}

.app-page-layout__main.app-page-layout__main--mb-0 {
  margin-bottom: 24px;
}

.app-page-layout__main.app-page-layout__main--mb-0 ~ .app-page-layout__side,
.app-page-layout__main.app-page-layout__main--mb-0 > * {
  margin-bottom: 0;
}

.app-page-layout__main {
  flex-grow: 1;
}

.app-page-layout__side {
  width: 100%;
  flex-basis: 100%;
}

.app-page-layout--navigation {
  justify-content: space-between;
}

.app-page-layout__divider {
  border-bottom: 1px solid #c5e0f4;
}

.app-page-layout-buttons {
  min-width: initial;
  padding: 0 16px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

@media only screen and (min-width: 480px) {
  .app-page-layout-buttons {
    min-width: 400px;
    flex-wrap: nowrap;
    flex-basis: 0;
    flex-grow: 999;
  }
}
@media only screen and (min-width: 1024px) {
  .app-page-layout {
    justify-content: space-between;
  }
  .app-page-layout__main,
  .app-page-layout__side,
  .app-page-layout__main > * {
    margin-bottom: 64px;
  }
  .app-page-layout__main--s {
    margin-top: 0;
  }
  .app-page-layout__main--s > * {
    margin-bottom: 24px;
  }
  .app-page-layout__side {
    flex-basis: 300px;
  }
  .app-page-layout .app-page-layout__side {
    flex-grow: initial;
  }
  .app-page-layout > :not(.app-page-layout__side) {
    flex-basis: 63%;
    flex-grow: initial;
  }
  .app-page-layout--listing-content > :not(.app-page-layout__side) {
    flex-basis: 0;
    flex-grow: 999;
  }
  .app-page-layout--listing-content > :first-child {
    padding-right: 24px;
  }
}
.app-reading-width {
  max-width: 740px;
}

.app-show-more-show-less {
  display: block;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  color: #025e8d;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
.app-show-more-show-less.visited, .app-show-more-show-less:visited {
  color: #01324b;
}
.app-show-more-show-less.hover, .app-show-more-show-less:hover {
  color: #01324b;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.app-show-more-show-less.active, .app-show-more-show-less:active {
  color: #025e8d;
}
.app-show-more-show-less.focus, .app-show-more-show-less:focus {
  outline: 4px solid #0088cc;
}

.app-show-more-show-less__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  width: clamp(1rem, 4vw, 1.5rem);
  height: clamp(1rem, 4vw, 1.5rem);
  vertical-align: middle;
  transition-duration: 0.2s;
}

.app-show-more-show-less--expanded .app-show-more-show-less__icon {
  transform: rotate(180deg);
}

[data-show-more-show-less-trigger] span {
  font-size: 0.875rem;
}

[data-show-more-show-less-target].show-more-show-less.app-show-more-show-less--expanded {
  display: block;
  -webkit-line-clamp: unset;
  line-clamp: unset;
  -webkit-box-orient: unset;
  overflow: initial;
}

@media only screen and (min-width: 480px) {
  [data-show-more-show-less-trigger-text] {
    font-size: inherit;
  }
}
.u-clearfix::before, .u-clearfix::after {
  content: "";
  display: table;
}
.u-clearfix::after {
  clear: both;
}

.u-clear-both {
  clear: both;
}

.u-clear-left {
  clear: left;
}

.u-clear-right {
  clear: right;
}

.u-clear-float {
  float: none;
}

.u-color-open-access {
  color: #b74616;
}

.u-color-inherit {
  color: inherit;
}

.u-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.u-display-block {
  display: block;
}

.u-display-inline {
  display: inline;
}

.u-display-inline-block {
  display: inline-block;
}

.u-display-flex {
  display: flex;
  width: 100%;
}

.u-display-inline-flex {
  display: inline-flex;
}

.u-display-grid {
  display: grid;
}

.u-display-table {
  display: table;
}

.u-flex-direction-row {
  flex-direction: row;
}

.u-flex-direction-column {
  flex-direction: column;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-baseline {
  align-items: baseline;
}

.u-justify-content-space-between {
  justify-content: space-between;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-left {
  justify-content: left;
}

.u-flex-grow {
  flex: 1 0 auto;
}

.u-flex-grow-shrink {
  flex: 1 1 auto;
}

.u-flex-shrink {
  flex: 0 1 auto;
}

.u-flex-static {
  flex: 0 0 auto;
}

.u-align-self-center {
  align-self: center;
}

.u-align-self-start {
  align-self: flex-start;
}

.u-align-self-end {
  align-self: flex-end;
}

.u-float-right {
  float: right;
}

.u-float-left {
  float: left;
}

.u-display-none {
  display: none;
}

/* hide from both screenreaders and browsers */
.u-hide,
.js .u-js-hide {
  display: none;
  visibility: hidden;
}
.u-hide:first-child + *,
.js .u-js-hide:first-child + * {
  margin-block-start: 0;
}

/* show to both screenreaders and browsers */
.u-show,
.js .u-js-show {
  display: block;
  visibility: visible;
}

.u-show-inline,
.js .u-js-show-inline {
  display: inline;
  visibility: visible;
}

/* hide only visually, but have it available for screenreaders */
.u-visually-hidden,
.js .u-js-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.u-visually-hidden--off,
.js .u-js-visually-hidden--off {
  border: 0;
  clip: initial;
  height: auto;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative;
  width: auto;
}

/* make invisible but retain dimensions */
.u-invisible {
  visibility: hidden;
}

/* hide only the text, keep element visible */
.u-hide-text,
.js .u-js-hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  vertical-align: bottom;
}

/* hiding overflow */
.u-hide-overflow {
  overflow: hidden;
}

.u-hide-sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media print {
  .u-hide-print {
    display: none;
  }
}
/**
 * media-query hiding
 * intentionally avoids 'max' and 'range' to prevent bloat
 */
@media only screen and (min-width: 480px) {
  .u-hide-at-xs,
  .js .u-js-hide-at-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xs:first-child + *,
  .js .u-js-hide-at-xs:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xs,
  .js .u-js-show-at-xs {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 768px) {
  .u-hide-at-sm,
  .js .u-js-hide-at-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-sm:first-child + *,
  .js .u-js-hide-at-sm:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-sm,
  .js .u-js-show-at-sm {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 876px) {
  .u-hide-at-md,
  .js .u-js-hide-at-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-md:first-child + *,
  .js .u-js-hide-at-md:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-md,
  .js .u-js-show-at-md {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1024px) {
  .u-hide-at-lg,
  .js .u-js-hide-at-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lg:first-child + *,
  .js .u-js-hide-at-lg:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-lg,
  .js .u-js-show-at-lg {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1220px) {
  .u-hide-at-xl,
  .js .u-js-hide-at-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xl:first-child + *,
  .js .u-js-hide-at-xl:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xl,
  .js .u-js-show-at-xl {
    display: block;
    visibility: visible;
  }
}
.u-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
}

.u-full-height {
  height: 100%;
}

.u-vertical-align-baseline {
  vertical-align: baseline;
}

.u-vertical-align-top {
  vertical-align: top;
}

.u-vertical-align-bottom {
  vertical-align: bottom;
}

.u-vertical-align-middle {
  vertical-align: middle;
}

.u-link-text {
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

.u-link-text--large {
  font-size: 1.25rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

.u-list-style-number {
  list-style-type: decimal;
}

.u-list-style-bullet {
  list-style-type: disc;
}

.u-list-style-dash > ::marker {
  content: "– ";
  font-size: 1.2em;
  line-height: 1;
}

.u-list-style-none {
  list-style-type: none;
}

.u-list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.u-list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-inline > li {
  display: inline;
}

.u-custom-list-number {
  float: left;
  width: 3em;
  padding-right: 0.5em;
  margin-left: -3.5em;
  text-align: right;
}

.u-list-comma-separated {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-comma-separated > li {
  display: inline;
}
.u-list-comma-separated > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.u-list-comma-separated > li:not(:only-child):last-child::before {
  content: " & ";
}

.u-list-truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-overflow-visible {
  overflow: visible;
}

.u-position-absolute {
  position: absolute;
}

.u-position-relative {
  position: relative;
}

.u-position-top {
  top: 0;
}

.u-position-left {
  left: 0;
}

.u-position-bottom {
  bottom: 0;
}

.u-position-right {
  right: 0;
}

.u-position-negative-right {
  right: 100%;
}

.u-scrollbar-thin {
  scrollbar-width: thin;
}

.u-ma-0 {
  margin: 0;
}

.u-ma-2 {
  margin: 2px;
}

.u-ma-4 {
  margin: 4px;
}

.u-ma-8 {
  margin: 8px;
}

.u-ma-16 {
  margin: 16px;
}

.u-ma-24 {
  margin: 24px;
}

.u-ma-32 {
  margin: 32px;
}

.u-ma-48 {
  margin: 48px;
}

.u-ma-64 {
  margin: 64px;
}

.u-ma-auto {
  margin: auto;
}

.u-mt-0 {
  margin-top: 0;
}

.u-mt-2 {
  margin-top: 2px;
}

.u-mt-4 {
  margin-top: 4px;
}

.u-mt-8 {
  margin-top: 8px;
}

.u-mt-16 {
  margin-top: 16px;
}

.u-mt-24 {
  margin-top: 24px;
}

.u-mt-32 {
  margin-top: 32px;
}

.u-mt-48 {
  margin-top: 48px;
}

.u-mt-64 {
  margin-top: 64px;
}

.u-mt-auto {
  margin-top: auto;
}

.u-mr-0 {
  margin-right: 0;
}

.u-mr-2 {
  margin-right: 2px;
}

.u-mr-4 {
  margin-right: 4px;
}

.u-mr-8 {
  margin-right: 8px;
}

.u-mr-16 {
  margin-right: 16px;
}

.u-mr-24 {
  margin-right: 24px;
}

.u-mr-32 {
  margin-right: 32px;
}

.u-mr-48 {
  margin-right: 48px;
}

.u-mr-64 {
  margin-right: 64px;
}

.u-mr-auto {
  margin-right: auto;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-2 {
  margin-bottom: 2px;
}

.u-mb-4 {
  margin-bottom: 4px;
}

.u-mb-8 {
  margin-bottom: 8px;
}

.u-mb-16 {
  margin-bottom: 16px;
}

.u-mb-24 {
  margin-bottom: 24px;
}

.u-mb-32 {
  margin-bottom: 32px;
}

.u-mb-48 {
  margin-bottom: 48px;
}

.u-mb-64 {
  margin-bottom: 64px;
}

.u-mb-auto {
  margin-bottom: auto;
}

.u-ml-0 {
  margin-left: 0;
}

.u-ml-2 {
  margin-left: 2px;
}

.u-ml-4 {
  margin-left: 4px;
}

.u-ml-8 {
  margin-left: 8px;
}

.u-ml-16 {
  margin-left: 16px;
}

.u-ml-24 {
  margin-left: 24px;
}

.u-ml-32 {
  margin-left: 32px;
}

.u-ml-48 {
  margin-left: 48px;
}

.u-ml-64 {
  margin-left: 64px;
}

.u-ml-auto {
  margin-left: auto;
}

.u-pa-0 {
  padding: 0;
}

.u-pa-2 {
  padding: 2px;
}

.u-pa-4 {
  padding: 4px;
}

.u-pa-8 {
  padding: 8px;
}

.u-pa-16 {
  padding: 16px;
}

.u-pa-24 {
  padding: 24px;
}

.u-pa-32 {
  padding: 32px;
}

.u-pa-48 {
  padding: 48px;
}

.u-pa-64 {
  padding: 64px;
}

.u-pt-0 {
  padding-top: 0;
}

.u-pt-2 {
  padding-top: 2px;
}

.u-pt-4 {
  padding-top: 4px;
}

.u-pt-8 {
  padding-top: 8px;
}

.u-pt-16 {
  padding-top: 16px;
}

.u-pt-24 {
  padding-top: 24px;
}

.u-pt-32 {
  padding-top: 32px;
}

.u-pt-48 {
  padding-top: 48px;
}

.u-pt-64 {
  padding-top: 64px;
}

.u-pr-0 {
  padding-right: 0;
}

.u-pr-2 {
  padding-right: 2px;
}

.u-pr-4 {
  padding-right: 4px;
}

.u-pr-8 {
  padding-right: 8px;
}

.u-pr-16 {
  padding-right: 16px;
}

.u-pr-24 {
  padding-right: 24px;
}

.u-pr-32 {
  padding-right: 32px;
}

.u-pr-48 {
  padding-right: 48px;
}

.u-pr-64 {
  padding-right: 64px;
}

.u-pb-0 {
  padding-bottom: 0;
}

.u-pb-2 {
  padding-bottom: 2px;
}

.u-pb-4 {
  padding-bottom: 4px;
}

.u-pb-8 {
  padding-bottom: 8px;
}

.u-pb-16 {
  padding-bottom: 16px;
}

.u-pb-24 {
  padding-bottom: 24px;
}

.u-pb-32 {
  padding-bottom: 32px;
}

.u-pb-48 {
  padding-bottom: 48px;
}

.u-pb-64 {
  padding-bottom: 64px;
}

.u-pl-0 {
  padding-left: 0;
}

.u-pl-2 {
  padding-left: 2px;
}

.u-pl-4 {
  padding-left: 4px;
}

.u-pl-8 {
  padding-left: 8px;
}

.u-pl-16 {
  padding-left: 16px;
}

.u-pl-24 {
  padding-left: 24px;
}

.u-pl-32 {
  padding-left: 32px;
}

.u-pl-48 {
  padding-left: 48px;
}

.u-pl-64 {
  padding-left: 64px;
}

.u-keyline {
  border-bottom: 1px solid #999999;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-keyline--thick {
  border-bottom: 2px solid #999999;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-shadow {
  border: 1px solid #000000;
  box-shadow: 5px 5px 5px #000000;
}

.u-button-reset {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.u-monospace {
  font-family: monospace;
}

.u-sans-serif {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.u-serif {
  font-family: "Merriweather", serif;
}

.u-small-caps {
  font-size: 0.8em; /* scale based on containing text */
  text-transform: uppercase;
}

.u-text-right {
  text-align: right;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-normal {
  font-weight: 400;
}

.u-text-bold {
  font-weight: 700;
}

.u-text-xxs {
  font-size: 0.75rem;
}

.u-text-xs {
  font-size: 0.875rem;
}

.u-text-s {
  font-size: 1rem;
}

.u-text-default {
  font-size: 1.125rem;
}

.u-text-md {
  font-size: 1.25rem;
}

.u-text-l {
  font-size: 1.5rem;
}

.u-text-xl {
  font-size: 1.75rem;
}

.u-text-xxl {
  font-size: 2rem;
}

.u-text-hl {
  font-size: 2.1875rem;
}

.u-text-hxl {
  font-size: 2.25rem;
}

.u-meta-large {
  font-weight: 400;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
}

.u-meta-medium {
  font-weight: 400;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
}

.u-meta-small {
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.u-double-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
  border-bottom: 1px solid;
}

.u-text-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.u-upper {
  text-transform: uppercase;
}

.u-lower {
  text-transform: lowercase;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
}

.u-unstyled {
  font-weight: normal;
  font-style: normal;
}

.u-h1 {
  font-size: 2.25rem;
  font-size: clamp(1.75rem, 4vw, 2.25rem);
  font-weight: 700;
  line-height: 1.2;
}

.u-h2 {
  font-size: 1.75rem;
  font-size: clamp(1.5rem, 4vw, 1.75rem);
  font-weight: 700;
  line-height: 1.2;
}

.u-h3 {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
}

.u-h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

.u-h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

.u-mbs-0 {
  margin-block-start: 0 !important;
}

body {
  font-size: 1.125rem;
  font-size: clamp(1rem, 4vw, 1.125rem);
}

.app-content-page .app-card-highlight__heading,
.app-content-page .app-card-collection__heading {
  margin: 0 0 8px;
  font-size: 1.25rem;
}

.app-content-page .app-card-highlight img {
  border-radius: initial;
}

.app-article-search {
  margin: 0 0 32px;
  border-left: 10px solid #3c9cd7;
  background-color: #f0f7fc;
  border-radius: 8px;
  padding: 16px;
  line-height: normal;
}

h1 + div .app-article-search {
  margin-top: -16px;
}

.app-article-search__link {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #025e8d;
}
.app-article-search__link:hover, .app-article-search__link:visited {
  color: #025e8d;
}

.app-article-search__icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 4px;
  fill: #025e8d;
}

.u-inline-separator:before {
  content: "|";
  display: inline;
  font-weight: 300;
  padding-left: 8px;
  margin-right: 8px;
  color: #c5e0f4;
}

.u-text-xxs {
  font-size: 0.75rem;
}

.u-text-xs {
  font-size: 0.875rem;
}

.u-text-s {
  font-size: 1rem;
}

.u-text-md {
  font-size: 1.25rem;
}

.u-text-l {
  font-size: 1.5rem;
}

.u-text-xl {
  font-size: 1.75rem;
}

.u-text-xxl {
  font-size: 2rem;
}

.u-text-hl {
  font-size: 2.1875rem;
}

.u-text-hxl {
  font-size: 2.25rem;
}

.eds-c-section-heading.u-mb-0 {
  margin-bottom: 0;
}

.app-vol-and-issues-item {
  margin-bottom: 24px;
}

@media only screen and (min-width: 480px) {
  .app-vol-and-issues-item {
    margin-bottom: 48px;
  }
}
.c-account-nav__anchor.is-open.eds-c-header__link::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzAyNWU4ZCIgaWQ9ImVkcy1pLXVzZXItc2luZ2xlLW1lZGl1bSI+PHBhdGggZD0iTTEyIDFhNSA1IDAgMSAxIDAgMTAgNSA1IDAgMCAxIDAtMTBabTAgMmEzIDMgMCAxIDAgMCA2IDMgMyAwIDAgMCAwLTZabS0uNDA2IDkuMDA4YTguOTY1IDguOTY1IDAgMCAxIDYuNTk2IDIuNDk0QTkuMTYxIDkuMTYxIDAgMCAxIDIxIDIxLjAyNVYyMmExIDEgMCAwIDEtMSAxSDRhMSAxIDAgMCAxLTEtMXYtLjk4NWMuMDUtNC44MjUgMy44MTUtOC43NzcgOC41OTQtOS4wMDdabS4zOSAxLjk5Mi0uMjk5LjAwNmMtMy42My4xNzUtNi41MTggMy4xMjctNi42NzggNi43NzVMNSAyMWgxMy45OThsLS4wMDktLjI2OGE3LjE1NyA3LjE1NyAwIDAgMC0xLjk3LTQuNTczbC0uMjE0LS4yMTNBNi45NjcgNi45NjcgMCAwIDAgMTEuOTg0IDE0WiIvPjwvc3ZnPgo=");
}

.eds-c-button {
  transition: none;
}

/*
list for submission guidelines temp
 */
.app-pointed-rectangle-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 48px;
}
.app-pointed-rectangle-list li {
  background-color: #f0f7fc;
  border: 1px solid #c5e0f4;
  border-radius: 12px;
  padding: 32px 8px;
  margin-bottom: 32px;
  text-align: center;
  font-weight: 700;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
}
.app-pointed-rectangle-list li::before, .app-pointed-rectangle-list li::after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: -12px;
  border-radius: 0 0 5px 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: rotate(53deg) skew(15deg);
}
.app-pointed-rectangle-list li::before {
  background-color: #f0f7fc;
  bottom: -12px;
  z-index: 1;
}
.app-pointed-rectangle-list li::after {
  background: #c5e0f4;
  bottom: -13px;
  z-index: 0;
}
.app-pointed-rectangle-list li:nth-child(even) {
  background-color: #ffffff;
}
.app-pointed-rectangle-list li:nth-child(even)::before {
  background-color: #ffffff;
}
.app-pointed-rectangle-list li:last-child::before, .app-pointed-rectangle-list li:last-child::after {
  content: none;
}

p:has(+ .app-pointed-rectangle),
p:has(+ p:empty),
p:empty:has(+ .app-pointed-rectangle) {
  margin-bottom: 16px;
}

.app-pointed-rectangle {
  background-color: #f0f7fc;
  font-weight: 700;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
  text-align: left;
}

.app-pointed-rectangle + br {
  display: none;
}

p:empty {
  display: none;
}

p:empty + h2 {
  margin-top: 3rem;
}

.app-calls-for-papers-jhomepage .eds-c-card-composable__container {
  flex-basis: 100%;
}

.app-calls-for-papers-jhomepage .eds-c-card-composable__sidebar--with-separator {
  gap: 16px;
}

.app-calls-for-papers-submission-status {
  font-weight: 700;
  margin-top: 2px;
}

.l-with-sidebar__main {
  max-width: 824px;
}

.u-mb-48-at-desktop {
  margin-bottom: 32px;
}
@media only screen and (min-width: 768px) {
  .u-mb-48-at-desktop {
    margin-bottom: 48px;
  }
}

.app-eb-orcid-icon {
  width: 24px;
  height: 24px;
}

.l-stack--multi + .l-stack--multi {
  margin-top: 48px;
}
@media only screen and (min-width: 480px) {
  .l-stack--multi + .l-stack--multi {
    margin-top: 64px;
  }
}

.u-line-height-base {
  line-height: 1.8;
}

.u-line-height-md {
  line-height: 1.5;
}

.u-line-height-tight {
  line-height: 1.4;
}

.u-line-height-tighter {
  line-height: 1.2;
}

.l-with-sidebar--journal-page {
  --with-sidebar--gap: 24px;
}
@media only screen and (min-width: 480px) {
  .l-with-sidebar--journal-page {
    --with-sidebar--gap: 69px;
  }
}
@media only screen and (min-width: 1024px) {
  .l-with-sidebar--journal-page {
    --with-sidebar--gap: 122px;
  }
}