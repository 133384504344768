.app-journal-navigation {
	margin-bottom: $t-a-space-relative-800;

	.feature-flag-journal_page_improvements_part_2 & {
		margin: 0;
	}
}

.app-journal-navigation-expander {
	background-color: $t-a-palette-default-white;
	border-top: 2px solid $t-a-palette-default-blue-light;
	border-bottom: 2px solid $t-a-palette-default-blue-light;
	padding: $t-a-space-relative-400 0;

	& h2 {
		@include u-container;
		margin-bottom: $t-a-space-relative-600;
	}

	.js & {
		display: none;
	}
}

.app-journal-navigation__mobile-trigger {
	@include u-button-reset;
	display: flex;
	justify-content: flex-start;
	gap: $t-a-space-relative-200;
	align-items: center;
	width: 100%;
	color: $t-a-palette-default-greyscale-700;
	font-family: $t-a-typography-font-family-default;
	font-size: $t-a-typography-font-size-s;
	font-weight: $t-a-typography-font-weight-bold;
	line-height: $t-a-typography-line-height-md;
	padding: $t-a-space-relative-400 $t-a-space-relative-600;
	border-top: 1px solid $t-a-palette-default-blue-light;
	border-bottom: 1px solid $t-a-palette-default-blue-light;

	& svg {
		width: 24px;
		height: 24px;
		fill: $t-a-palette-default-greyscale-700;
		color: $t-a-palette-default-greyscale-700;
	}

	&.is-open {
		color: $t-a-palette-default-blue-medium-700;
		border-bottom: none;
	}

	&.is-open svg {
		transform: rotate(180deg);
		fill: $t-a-palette-default-blue-medium-700;
		color: $t-a-palette-default-blue-medium-700;
	}
}

.app-navigation__no-dropdown--active {
	display: flex;
}

.app-navigation__no-dropdown--active .app-accordion__strip {
	flex-grow: 0;
	width: 8px;
	background-color: $t-a-palette-default-blue-medium-700;
	margin-right: -8px;
}

.app-navigation__no-dropdown--active a {
	color: $t-a-palette-default-blue-medium-700;
}

@include from-wide-tablet {
	.app-journal-navigation {
		margin-bottom: $t-a-space-relative-1200;
	}

	.feature-flag-journal_page_improvements_part_2 .app-journal-navigation {
		margin-bottom: 0;
	}

	.app-journal-navigation .app-navigation__desktop-menu {
		@include u-container;
		padding: $t-a-space-relative-200 $t-a-space-relative-400;
	}

	.app-journal-navigation__mobile-trigger {
		display: none;
	}
}
